import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import * as appRedux from "../../../../../../popleads/app/redux/appRedux";

import PDFCommonStyles from "../../../pdf-common.module.scss";
import styles from "./Page1.module.scss";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./../../../../../../../_metronic/_helpers/AssetsHelpers";

class Page1 extends React.Component {
    render() {
        return (
            <>
                <div className={PDFCommonStyles.page}>
                    <div className={styles.top}>
                        <span className="svg-icon-xs">
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/leoron_logo.svg")} />
                        </span>
                    </div>
                    <div className={styles.bottom}>
                        <h2 className={PDFCommonStyles.subheader}>Evaluation Form Report</h2>
                        <h1 className={PDFCommonStyles.header}>{this.props.surveyCampaign?.campaignName}</h1>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    ...appRedux.actions
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Page1));
