//import { get } from "../../../networking/RequestService";

export const actionTypes = {
  SelectEmployee: "[Select Employee] Action",
  SelectDepartment: "[Select Department] Action",
  SelectTrainer: "[Select Trainer] Action",
  SelectCampaignTypeList: "[Select Campaign Type List] Action",
  SelectDate: "[Select Date] Action",
  SelectDateSurvey: "[Select Date Survey] Action",
  SelectCampaignType: "[Select Campaign Type] Action",
  SelectTopEmployees: "[Select Top Employees] Action",
  SelectCountry_Aside: "[Select Country] Action",
  SelectAccount_Aside: "[Select Account] Action",
  SelectIndustry_Aside: "[Select Industry] Action",
  SelectPortfolio_Aside: "[Select Portfolio] Action",
  SetRateForRadialBar: "[Set Rate For Radial] Action",
  SetDataForDonutBar: "[Set Data For Donut] Action",
  SetDataForTotalCampaignsDonutBar: "[Set Data For Total Campaigns Donut] Action",
  SetDataForLineGraph: "[Set Data For Line] Action",
  SetKeyForLineGraph: "[Set Key For Line] Action",
  SetKeyForAreaGraph: "[Set Key For Area] Action",
  SetDataAndKeyForLineGraph: "[Set Data and Key For Line] Action",
  SetKeyOfTopEmployee: "[SetKey For Top Employees] Action",
  SetKeyOfYoYGraph: "[SetKey For YoY Graph] Action",
  SetDataForSales: "[Set Data For Sales] Action",
  SetIsLoadingForSales: "[Set isLoading For Sales] Action",
  SetIsLoadingForLineGraph: "[Set isLoading For Line] Action",
  IsSelectAllEmployees: "[Set isSelectAll For Employees] Action",
  IsSelectAllDepartments: "[Set isSelectAll For Departments] Action",
  IsSelectAllInquiryTypes: "[Set isSelectAll For Inquiry Type] Action",
  IsSelectAllFranchise: "[Set isSelectAll For Franchise] Action",
  SelectInquiryType: "[Set Selected Inquiry Type] Action",
  SelectFranchise: "[Set Selected Franchise] Action",
  IsPageIdInquiry: "[Set Is Page IdInquiry] Action",
};

const initialReportsState = {
  selectedEmployee: undefined,
  selectedDepartment: undefined,
  selectedTrainer: undefined,
  selectedCampaignTypeList: undefined,
  selectedInquiryType: undefined,
  selectedFranchise: undefined,
  selectedDate: {
    startDate: undefined,
    endDate: undefined,
    predefinedTag: undefined
  },
  selectedDateSurvey: {
    startDate: undefined,
    endDate: undefined,
    predefinedTag: undefined
  },
  selectedCampaignType: undefined,
  selectedCountry_Aside: [],
  selectedAccount_Aside: [],
  selectedIndustry_Aside: [],
  selectedPortfolio_Aside: [],
  path: undefined,
  selectedTopEmployees: undefined,
  rateOfRadialBar: undefined,
  dataOfDonutBar: undefined,
  totalCampaignsDataOfDonutBar: undefined,
  dataOfLineGraph: undefined,
  keyOfLineGraph: 'month',
  dataAndKeyOfLineGraph: { data: undefined, key: 'month' },
  keyOfTopEmployee: "thisYear",
  keyOfYoYGraph: "paid",
  keyOfAreaGraph: 'month',
  dataForSales: undefined,
  isLoadingForSales: true,
  isLoadingForLineGraph: true,
  isSelectAllEmployees: false,
  isSelectAllDepartments: false,
  isSelectAllFranchise: false,
  IsPageIdInquiry: false
};

export const reducer = (state = initialReportsState, action) => {
  switch (action.type) {

    case actionTypes.SelectEmployee: {
      //console.log("actionType.SelectEmployee")
      const { selectedEmployee } = action.payload;
      return { ...state, selectedEmployee }
    }

    case actionTypes.SelectTrainer: {
      const { selectedTrainer } = action.payload;
      return { ...state, selectedTrainer }
    }

    case actionTypes.SelectCampaignTypeList: {
      console.log("p: ", action.payload)
      const { selectedCampaignTypeList } = action.payload;
      return { ...state, selectedCampaignTypeList }
    }

    case actionTypes.SelectDepartment: {
      const { selectedDepartment } = action.payload;
      return { ...state, selectedDepartment }
    }

    case actionTypes.SelectInquiryType: {
      const { selectedInquiryType } = action.payload;
      return { ...state, selectedInquiryType }
    }

    case actionTypes.SelectFranchise: {
      const { selectedFranchise } = action.payload;
      return { ...state, selectedFranchise }
    }

    case actionTypes.SelectDate: {
      //console.log("actionType.SelectDate")
      const { selectedDate } = action.payload;
      return { ...state, selectedDate }
    }
    case actionTypes.SelectDateSurvey: {
      //console.log("actionType.SelectDate")
      const { selectedDateSurvey } = action.payload;
      return { ...state, selectedDateSurvey }
    }

    case actionTypes.SelectCampaignType: {
      const { selectedCampaignType, path } = action.payload;
      return { ...state, selectedCampaignType, path }
    }

    case actionTypes.SelectTopEmployees: {
      const { selectedTopEmployees } = action.payload;
      return { ...state, selectedTopEmployees }
    }

    case actionTypes.SelectCountry_Aside: {
      const { selectedCountry_Aside } = action.payload;
      return { ...state, selectedCountry_Aside }
    }

    case actionTypes.SelectAccount_Aside: {
      const { selectedAccount_Aside } = action.payload;
      return { ...state, selectedAccount_Aside }
    }

    case actionTypes.SelectIndustry_Aside: {
      const { selectedIndustry_Aside } = action.payload;
      return { ...state, selectedIndustry_Aside }
    }

    case actionTypes.SelectPortfolio_Aside: {
      const { selectedPortfolio_Aside } = action.payload;
      return { ...state, selectedPortfolio_Aside }
    }

    case actionTypes.SetRateForRadialBar: {
      const { rateOfRadialBar } = action.payload;
      return { ...state, rateOfRadialBar }
    }

    case actionTypes.SetDataForDonutBar: {
      const { dataOfDonutBar } = action.payload;
      return { ...state, dataOfDonutBar }
    }
    case actionTypes.SetDataForTotalCampaignsDonutBar: {
      const { totalCampaignsDataOfDonutBar } = action.payload;
      return { ...state, totalCampaignsDataOfDonutBar }
    }

    case actionTypes.SetDataForLineGraph: {
      const { dataOfLineGraph } = action.payload;
      return { ...state, dataOfLineGraph }
    }

    case actionTypes.SetDataAndKeyForLineGraph: {
      const { dataAndKeyOfLineGraph } = action.payload;
      return { ...state, dataAndKeyOfLineGraph }
    }
    case actionTypes.SetKeyOfTopEmployee: {
      const { keyOfTopEmployee } = action.payload;
      return { ...state, keyOfTopEmployee }
    }
    case actionTypes.SetKeyOfYoYGraph: {
      const { keyOfYoYGraph } = action.payload;
      return { ...state, keyOfYoYGraph }
    }

    case actionTypes.SetKeyForLineGraph: {
      const { keyOfLineGraph } = action.payload;
      return { ...state, keyOfLineGraph }
    }

    case actionTypes.SetKeyForAreaGraph: {
      const { keyOfAreaGraph } = action.payload;
      return { ...state, keyOfAreaGraph }
    }

    case actionTypes.SetDataForSales: {
      const { dataForSales } = action.payload;
      return { ...state, dataForSales }
    }

    case actionTypes.SetIsLoadingForSales: {
      const { isLoadingForSales } = action.payload;
      return { ...state, isLoadingForSales }
    }

    case actionTypes.SetIsLoadingForLineGraph: {
      const { isLoadingForLineGraph } = action.payload;
      return { ...state, isLoadingForLineGraph }
    }

    case actionTypes.IsSelectAllEmployees: {
      const { isSelectAllEmployees } = action.payload;
      return { ...state, isSelectAllEmployees }
    }

    case actionTypes.IsSelectAllDepartments: {
      const { isSelectAllDepartments } = action.payload;
      return { ...state, isSelectAllDepartments }
    }

    case actionTypes.IsSelectAllInquiryTypes: {
      const { isSelectAllInquiryTypes } = action.payload;
      return { ...state, isSelectAllInquiryTypes }
    }

    case actionTypes.IsSelectAllFranchise: {
      const { isSelectAllFranchise } = action.payload;
      return { ...state, isSelectAllFranchise }
    }

    case actionTypes.IsPageIdInquiry: {
      const { isPageIdInquiry } = action.payload;
      return { ...state, isPageIdInquiry }
    }

    default:
      return state;
  }
}

export const actions = {
  selectEmployee: (selectedEmployee) => ({
    type: actionTypes.SelectEmployee,
    payload: { selectedEmployee }
  }),
  selectDepartment: (selectedDepartment) => ({
    type: actionTypes.SelectDepartment,
    payload: { selectedDepartment }
  }),
  selectTrainer: (selectedTrainer) => ({
    type: actionTypes.SelectTrainer,
    payload: { selectedTrainer }
  }),
  selectCampaignTypeList: (selectedCampaignTypeList) => ({
    type: actionTypes.SelectCampaignTypeList,
    payload: { selectedCampaignTypeList }
  }),
  selectInquiryType: (selectedInquiryType) => ({
    type: actionTypes.SelectInquiryType,
    payload: { selectedInquiryType }
  }),
  selectFranchise: (selectedFranchise) => ({
    type: actionTypes.SelectFranchise,
    payload: { selectedFranchise }
  }),
  selectDate: (selectedDate) => ({
    type: actionTypes.SelectDate,
    payload: { selectedDate }
  }),
  selectDateSurvey: (selectedDateSurvey) => ({
    type: actionTypes.SelectDateSurvey,
    payload: { selectedDateSurvey }
  }),
  selectCampaignType: (selectedCampaignType, path) => ({
    type: actionTypes.SelectCampaignType,
    payload: { selectedCampaignType, path }
  }),
  selectTopEmployees: (selectedTopEmployees) => ({
    type: actionTypes.SelectTopEmployees,
    payload: { selectedTopEmployees }
  }),
  selectCountry_Aside: (selectedCountry_Aside) => ({
    type: actionTypes.SelectCountry_Aside,
    payload: { selectedCountry_Aside }
  }),
  selectAccount_Aside: (selectedAccount_Aside) => ({
    type: actionTypes.SelectAccount_Aside,
    payload: { selectedAccount_Aside }
  }),
  selectIndustry_Aside: (selectedIndustry_Aside) => ({
    type: actionTypes.SelectIndustry_Aside,
    payload: { selectedIndustry_Aside }
  }),
  selectPortfolio_Aside: (selectedPortfolio_Aside) => ({
    type: actionTypes.SelectPortfolio_Aside,
    payload: { selectedPortfolio_Aside }
  }),
  setRateForRadialBar: (rateOfRadialBar) => ({
    type: actionTypes.SetRateForRadialBar,
    payload: { rateOfRadialBar }
  }),
  setDataForDonutBar: (dataOfDonutBar) => ({
    type: actionTypes.SetDataForDonutBar,
    payload: { dataOfDonutBar }
  }),
  setDataForTotalCampaignsDonutBar: (totalCampaignsDataOfDonutBar) => ({
    type: actionTypes.SetDataForTotalCampaignsDonutBar,
    payload: { totalCampaignsDataOfDonutBar }
  }),
  setDataForLineGraph: (dataOfLineGraph) => ({
    type: actionTypes.SetDataForLineGraph,
    payload: { dataOfLineGraph }
  }),
  setKeyForLineGraph: (keyOfLineGraph) => ({
    type: actionTypes.SetKeyForLineGraph,
    payload: { keyOfLineGraph }
  }),
  setKeyForAreaGraph: (keyOfAreaGraph) => ({
    type: actionTypes.SetKeyForAreaGraph,
    payload: { keyOfAreaGraph }
  }),
  setDataForSales: (dataForSales) => ({
    type: actionTypes.SetDataForSales,
    payload: { dataForSales }
  }),
  setIsLoadingForSales: (isLoadingForSales) => ({
    type: actionTypes.SetIsLoadingForSales,
    payload: { isLoadingForSales }
  }),
  setIsLoadingForLineGraph: (isLoadingForLineGraph) => ({
    type: actionTypes.SetIsLoadingForLineGraph,
    payload: { isLoadingForLineGraph }
  }),
  setDataAndKeyForLineGraph: (dataAndKeyOfLineGraph) => ({
    type: actionTypes.SetDataAndKeyForLineGraph,
    payload: { dataAndKeyOfLineGraph }
  }),


  SetKeyOfTopEmployee: (keyOfTopEmployee) => ({
    type: actionTypes.SetKeyOfTopEmployee,
    payload: { keyOfTopEmployee }
  }),
  SetKeyOfYoYGraph: (keyOfYoYGraph) => ({
    type: actionTypes.SetKeyOfYoYGraph,
    payload: { keyOfYoYGraph }
  }),


  setIsSelectAllEmployees: (isSelectAllEmployees) => ({
    type: actionTypes.IsSelectAllEmployees,
    payload: { isSelectAllEmployees }
  }),

  setIsSelectAllInquiryTypes: (isSelectAllInquiryTypes) => ({
    type: actionTypes.IsSelectAllInquiryTypes,
    payload: { isSelectAllInquiryTypes }
  }),

  setIsSelectAllFranchise: (isSelectAllFranchise) => ({
    type: actionTypes.IsSelectAllFranchise,
    payload: { isSelectAllFranchise }
  }),

  setIsSelectAllDepartments: (isSelectAllDepartments) => ({
    type: actionTypes.IsSelectAllDepartments,
    payload: { isSelectAllDepartments }
  }),

  setIsPageIdInquiry: (isPageIdInquiry) => ({
    type: actionTypes.IsPageIdInquiry,
    payload: { isPageIdInquiry }
  })
};
