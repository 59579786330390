import React from "react";
import { DebounceInput } from "react-debounce-input";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./../../../../_metronic/_helpers/AssetsHelpers";
import PropTypes from "prop-types";
import clsx from "clsx";

// import searchIconPrimary from "../../assets/img/Icon_primary.png";

/**
 * @typedef {Object} SearchInputProps
 * @property {"primary-outline-input" |
 *   "secondary-outline-input" |
 *   "primary-filled-input" |
 *   "secondary-filled-input" |
 *   "rest-outline-input" |
 *   "disable-outline-input" } [variant]
 */

/**
 * @type {React.FC<
 *   import('react-debounce-input').DebounceInputProps<
 *     HTMLInputElement,
 *     React.InputHTMLAttributes<HTMLInputElement>
 *   > &
 *   SearchInputProps
 * >}
 */
const SearchInput = ({
    variant = "primary-outline-input",
    icon = "/media/svg/popleads/search_icon.svg",
    className,
    ...props
}) => {
    return (
        <div className={clsx("input-icon custom-input-icon", variant)}>
            <DebounceInput className={clsx("form-control", className)} {...props} />
            <span className="custom-search-icon">
                {/* <i className="flaticon2-search-1 text-muted"></i> */}
                <SVG src={toAbsoluteUrl(`${icon}`)} />
                {/* <img src={searchIconPrimary} alt="search icon"/> */}
            </span>
        </div>
    );
};

SearchInput.propTypes = {
    variant: PropTypes.oneOf([
        "primary-outline-input",
        "secondary-outline-input",
        "primary-filled-input",
        "secondary-filled-input",
        "rest-outline-input",
        "disable-outline-input"
    ]),
    ...DebounceInput.propTypes
};

export default SearchInput;
