import moment from "moment";
import React from "react";
import styles from "./Page3.module.scss";

const AnswerList = ({ answers }) => {
    return (
        <div className="mt-3">
            <div className={`d-flex justify-content-between ${styles.answerListTitle}`}>
                <p className={`${styles.responses} `}>Responses</p>
                <p className={`${styles.responses} `}>Date</p>
            </div>
            <ol className={styles.answerList}>
                {answers.map((answer, index) => {
                    return (
                        <li key={"answer_list__" + index} className={"mt-5"}>
                            <div className={`d-flex justify-content-between ${styles.answerListItem}`}>
                                <div>{answer.text}</div>
                                <div className="flex-shrink-0">
                                    {moment(answer.createdTime).format("DD/MM/YYYY hh:mm")}
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};

export default AnswerList;
