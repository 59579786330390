import React from 'react';
import Select from 'react-select';
import { connect } from "react-redux";
import * as app from "../../../app/redux/appRedux";
import * as reportsRedux from "../../reports/redux/reportsRedux";
import { post } from "../../../networking/RequestService";
import SelectInput from "../components/inputs/selectInput"

class TrainerFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            trainer: "",
            inputValue: "",
            options: [],
        }
    }



    onChange = selectedOption => {
        this.props.selectTrainer(selectedOption);
        this.setState({ trainer: selectedOption })
    };

    onInputChange = (str, { action }) => {
        if (action === "set-value") return false;
        console.log("str ", str)
        this.setState({ inputValue: str });
    };

    saveUsersToState(users) {
        const options = [];
        if (users) {
            for (let i = 0; i < users.length; i++) {
                const obj = { value: users[i].id, label: users[i].name };
                options.push(obj);
            }
        }
        this.setState({ options });

    }

    getUsers() {
        const companyUsers = {
            fn: "getTrainerList",
            rowsPerPage:'1000',
            start:0
        }

        post(companyUsers).then(({ data: { trainerList } }) => {
            console.log("trainerList: ", trainerList);
            this.saveUsersToState(trainerList);
        });
    }

    componentDidMount() {
        this.getUsers();
    }

    render() {
        return (
            <>
                {this.props.isMultiSelect ?
                    <SelectInput
                        object={{
                            //  fn: this.getUsers().fn,
                            //  unitId: this.getUsers().unitId,
                            name: "Trainer",
                            data: {
                                options: this.state.options,
                            },
                            multi: true,
                            allSelectable: true
                        }}
                        onChange={this.onChange}
                        onInputChange={this.onInputChange}
                        infinite={false}

                    />
                    :
                    <Select
                        className="selectInputNewFilter_single"
                        classNamePrefix="selectInputNewFilter"
                        options={this.state.options}
                        defaultValue={this.props.trainer}
                        value={this.props.trainer}
                        onChange={this.onChange}
                        closeMenuOnSelect={true}
                        isMulti={this.props.isMultiSelect}
                    />
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        trainer: state.reports.selectedTrainer,
    };
};

const mapDispatchToProps = {
    ...reportsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainerFilter);
