import { actionTypes } from "../app/modules/Auth/_redux/authRedux";
import * as util from "../app/popleads/app/functions/util";


export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      let {
        auth: { authToken }
      } = store.getState();

      if (!authToken) {
        authToken = util.getCookieValue('CRM_SID');
      }
      
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)

  );

  //PopLeads change
  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    console.log("Request Error = ", error);
    if (error && error.response && error.response.status === 401) {
      util.eraseCookie('CRM_SID');
      store.dispatch({ type: actionTypes.Logout });
    }
    return Promise.reject(error);
  });

}
