import React from "react";
import EmployeeFilter from "./components/EmployeeFilter";
import DepartmentFilter from "./components/DepartmentFilter";
import DateFilter from "./components/DateFilter";
import TypeFilter from "./components/TypeFilter";
import IndustryFilter from "./components/IndustryFilter";
import SearchFilter from "./components/SearchFilter";
import InquiryTypeFilter from "./components/InquiryTypeFilter";
import FranchiseFilter from "./components/FranchiseFilter";
import TrainerFilter from "./components/TrainerFilter";
import CampaignTypeFilter from "./components/CampaignTypeFilter";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import * as reportsRedux from "../reports/redux/reportsRedux";
import * as appRedux from "../../app/redux/appRedux";
import * as accountsRedux from "../accounts/redux/accountsRedux";
import * as trainingRedux from "../training/redux/trainingRedux";
import * as campaignsRedux from "../campaigns/redux/campaignsRedux";
import * as surveyRedux from "../survey/redux/surveyRedux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import * as util from "../../app/functions/util";
import moment from "moment";
import { NavLink } from "react-router-dom";
import NewFilter from "./NewFilter";
import Button from "../../components/Button";
import Select from '../../app/partials/Select';
import FilterDrawer from "../drawer/FilterDrawer";
// import Button from './../../components/Button';

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggle: false,
            employee: null,
            department: null,
            state: null,
            type: null,
            industry: null,
            inquiryType: null,
            franchise: null,
            trainer: null,
            campaignTypeList: null,
            employeeFilter: this.props.employeeFilter,
            departmentFilter: this.props.departmentFilter,
            trainerFilter: this.props.trainerFilter,
            campaignTypeListFilter: this.props.campaignTypeListFilter,
            dateFilter: this.props.dateFilter,
            dateFilterSurvey: this.props.dateFilterSurvey,
            gridOrTable: this.props.gridOrTable,
            gridOrTableFilter: this.props.gridOrTableFilter,
            inquiryTypeFilter: this.props.inquiryTypeFilter,
            franchiseFilter: this.props.franchiseFilter,
            isTrainerEditPermission: false
        };

        const queryEmployee = JSON.parse(util.getUrlQueryParam("employee"));
        const queryDepartment = JSON.parse(util.getUrlQueryParam("department"));
        const queryDate = JSON.parse(util.getUrlQueryParam("date"));
        const queryInquiry = JSON.parse(util.getUrlQueryParam("inquiry"));
        const queryFranchise = JSON.parse(util.getUrlQueryParam("franchise"));
        const queryTrainer = JSON.parse(util.getUrlQueryParam("trainer"));
        const queryCampaignType = JSON.parse(util.getUrlQueryParam("campaignType"));

        if (queryEmployee) {
            this.state.employee = queryEmployee;
            this.props.selectEmployee(queryEmployee);
        } else if (this.props.selectedEmployee) {
            this.setLabelForFilter("employee", this.props.selectedEmployee);
        } else {
            this.state.employee = "All Employees";
        }

        if (queryTrainer) {
            this.state.trainer = queryTrainer;
            this.props.selectTrainer(queryTrainer);
        } else if (this.props.selectedTrainer) {
            this.setLabelForFilter("trainer", this.props.selectedTrainer);
        } else {
            this.state.trainer = "All Trainers";
        }

        if (queryCampaignType) {
            this.state.campaignTypeList = queryCampaignType;
            this.props.selectCampaignTypeList(queryCampaignType);
        } else if (this.props.selectedCampaignTypeList) {
            console.log("1: ", this.props.selectedCampaignTypeList);
            this.setLabelForFilter("campaignType", this.props.selectedCampaignTypeList);
        } else {
            this.state.campaignTypeList = "All Campaign Types";
        }

        if (queryDepartment) {
            for (let i = 0; i < queryDepartment.length; i++) {
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxampxxx", "&");
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxqmaxxx", "?");
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxequxxx", "=");
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxcomxxx", ",");
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxdotxxx", ".");
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxdquxxx", '"');
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxsquxxx", "'");
            }
            this.state.department = queryDepartment;
            this.props.selectDepartment(queryDepartment);
        } else if (this.props.selectedDepartment) {
            this.state.department = this.props.selectedEmployee;
        } else {
            this.state.department = "All Units";
        }

        if (queryDate) {
            console.log("queryDate: ", queryDate);

            this.props.selectDate({
                startDate: moment(queryDate.startDate, "yyyy-MM-DD"),
                endDate: moment(queryDate.endDate, "yyyy-MM-DD"),
                predefinedTag: queryDate.predefinedTag,
                dateString: queryDate.dateString
            });
            this.state.date = queryDate.dateString;
        } else if (!this.props.selectedDate.startDate) {
            this.props.selectDate({
                startDate: moment()
                    .startOf("year")
                    .toDate(),
                endDate: moment()
                    .endOf("year")
                    .toDate(),
                predefinedTag: "thisYear",
                dateString: "This Year"
            });
            this.state.date = "This Year";
        } else if (this.props.selectedDate.dateString) {
            this.state.date = this.props.selectedDate.dateString;
        } else {
            this.state.date = "This Year";
        }

        if (queryDate && this.props.dateFilterSurvey) {
            console.log("queryDate: ", queryDate);
            this.props.selectDateSurvey({
                startDate: moment(queryDate.startDate, "yyyy-MM-DD"),
                endDate: moment(queryDate.endDate, "yyyy-MM-DD"),
                predefinedTag: queryDate.predefinedTag,
                dateString: queryDate.dateString
            });
            this.state.dateSurvey = queryDate.dateString;
        } else if (!this.props.selectedDateSurvey.startDate) {
            this.props.selectDateSurvey({
                startDate: null,
                endDate: new Date(),
                predefinedTag: "allTime",
                dateString: "All Time"
            });

            this.state.dateSurvey = "All Time";
        } else if (this.props.selectedDateSurvey.dateString) {
            this.state.dateSurvey = this.props.selectedDateSurvey.dateString;
        } else {
            this.state.dateSurvey = "All Time";
        }

        if (this.props.selectedType) {
            this.state.type = this.props.selectedType;
        } else {
            this.state.type = "All Types";
        }

        if (this.props.selectedIndustry) {
            this.state.industry = this.props.selectedIndustry;
        } else {
            this.state.industry = "All Industries";
        }

        if (queryInquiry) {
            for (let i = 0; i < queryInquiry.length; i++) {
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxampxxx", "&");
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxqmaxxx", "?");
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxequxxx", "=");
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxcomxxx", ",");
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxdotxxx", ".");
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxdquxxx", '"');
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxsquxxx", "'");
            }
            this.state.inquiryType = queryInquiry;
            this.props.selectInquiryType(queryInquiry);
        } else if (this.props.selectedInquiryType) {
            this.setLabelForFilter("inquiryType", this.props.selectedInquiryType);
        } else {
            this.state.inquiryType = "All Types";
        }

        if (queryFranchise) {
            for (let i = 0; i < queryFranchise.length; i++) {
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxampxxx", "&");
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxqmaxxx", "?");
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxequxxx", "=");
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxcomxxx", ",");
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxdotxxx", ".");
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxdquxxx", '"');
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxsquxxx", "'");
            }
            this.state.franchise = queryFranchise;
            this.props.selectFranchise(queryFranchise);
        } else if (this.props.selectedFranchise) {
            this.setLabelForFilter("franchise", this.props.selectedFranchise);
        } else {
            this.state.franchise = "HQ";
        }
    }

    setFilters() {
        this.setState({
            employeeFilter: this.props.employeeFilter,
            departmentFilter: this.props.departmentFilter,
            trainerFilter: this.props.trainerFilter,
            campaignTypeListFilter: this.props.campaignTypeListFilter,
            dateFilter: this.props.dateFilter,
            dateFilterSurvey: this.props.dateFilterSurvey,
            gridOrTableFilter: this.props.gridOrTableFilter,
            typeFilter: this.props.typeFilter,
            industryFilter: this.props.industryFilter,
            inquiryTypeFilter: this.props.inquiryTypeFilter,
            franchiseFilter: this.props.franchiseFilter,
            searchInput: this.props.searchInput,
            isEditButton: this.props.isEditButton,
            isOpenEditButton: this.props.isOpenEditButton,
            isOpenAccountEditButton: this.props.isOpenAccountEditButton,
            isAccountEditButton: this.props.isAccountEditButton,
            isOpenAccountMergeButton: this.props.isOpenAccountMergeButton,
            isAccountMergeButton: this.props.isAccountMergeButton,
            searchInput_precampaign: this.props.searchInput_precampaign,
            isPreCampaignButton: this.props.isPreCampaignButton,
            editMoreInfoButton: this.props.editMoreInfoButton,
            isOpenEditMoreInfoButton: this.props.isOpenEditMoreInfoButton,
            duplicateButton: this.props.duplicateButton,
            selectedLength_precampaign: this.props.selectedLength,
            isCourseEditButton: this.props.isCourseEditButton,
            isOpenCourseEditButton: this.props.isOpenCourseEditButton,
            showAddSurveyButton: this.props.showAddSurveyButton,
            showEditSurveyButton: this.props.showEditSurveyButton,
            statusEditSurveyButton: this.props.statusEditSurveyButton,
            surveyId: this.props.surveyId
        });
    }

    componentDidMount() {
        this.setFilters();
        this.setTrainerEdit();
    }

    setTrainerEdit() {
        if (this.props.user && this.props.user.permissions && this.props.user.permissions.TRAINER_EDIT === 1) {
            this.setState({ isTrainerEditPermission: true });
        }
    }

    componentDidUpdate(prevProps) {
        console.log("componentDidUpdate: ", this.props.selectedCampaignTypeList);
        if (this.props !== prevProps) {
            if (this.props.selectedEmployee) {
                this.setLabelForFilter("employee", this.props.selectedEmployee);
            }

            if (this.props.selectedDepartment) {
                this.setLabelForFilter("department", this.props.selectedDepartment);
            }

            if (this.props.selectedInquiryType) {
                this.setLabelForFilter("inquiryType", this.props.selectedInquiryType);
            }

            if (this.props.selectedFranchise) {
                this.setLabelForFilter("franchise", this.props.selectedFranchise);
            }

            if (this.props.selectedTrainer) {
                this.setLabelForFilter("trainer", this.props.selectedTrainer);
            }

            if (this.props.selectedCampaignTypeList) {
                this.setLabelForFilter("campaignType", this.props.selectedCampaignTypeList);
            }

            if (this.props.selectedDate) {
                if (this.props.selectDate.dateString !== this.state.date)
                    this.setState({ date: this.props.selectedDate.dateString });
            }
            if (this.props.selectedDateSurvey) {
                if (this.props.selectDateSurvey.dateString !== this.state.dateSurvey)
                    this.setState({ dateSurvey: this.props.selectedDateSurvey.dateString });
            }
            if (this.props.selectedType) {
                if (this.props.selectedType.label !== this.state.selectedType)
                    this.setState({ type: this.props.selectedType.label });
            }

            if (this.props.selectedIndustry) {
                if (this.props.selectedIndustry.label !== this.state.selectedIndustry)
                    this.setState({
                        industry: this.props.selectedIndustry.label
                    });
            }

            this.setFilters();
        }

        if (prevProps.gridOrTable !== this.props.gridOrTable) {
            this.setState({ gridOrTable: this.props.gridOrTable });
        }

        if (prevProps.user !== this.props.user) {
            this.setTrainerEdit();
        }
    }

    setLabelForFilter(filterName, filterArray) {
        if (filterName === "trainer") {
            if (Array.isArray(filterArray)) {
                if (filterArray.length === 0) {
                    this.setState({ trainer: "All Trainers" });
                }
                if (filterArray.length === 1) {
                    if (filterArray[0].label !== this.state.trainer) this.setState({ trainer: filterArray[0].label });
                } else if (filterArray.length > 1) {
                    this.setState({
                        trainer: filterArray.length + " Trainers"
                    });
                }
            } else if (filterArray.label !== this.state.trainer) this.setState({ trainer: filterArray.label });
        }

        if (filterName === "campaignType") {
            if (Array.isArray(filterArray)) {
                if (filterArray.length === 0) {
                    this.setState({ campaignTypeList: "All Campaign Types" });
                }
                if (filterArray.length === 1) {
                    if (filterArray[0].label !== this.state.campaignTypeList)
                        this.setState({ campaignTypeList: filterArray[0].label });
                } else if (filterArray.length > 1) {
                    this.setState({
                        campaignTypeList: filterArray.length + " Campaign Types"
                    });
                }
            } else if (filterArray.label !== this.state.campaignTypeList)
                this.setState({ campaignTypeList: filterArray.label });
        }

        if (filterName == "employee") {
            if (Array.isArray(filterArray)) {
                if (filterArray.length === 0) {
                    this.setState({ employee: "All Employees" });
                }
                if (filterArray.length === 1) {
                    if (filterArray[0].label !== this.state.employee) this.setState({ employee: filterArray[0].label });
                } else if (filterArray.length > 1) {
                    this.setState({
                        employee: filterArray.length + " Employees"
                    });
                }
            } else if (filterArray.label !== this.state.employee) this.setState({ employee: filterArray.label });
        } else if (filterName == "department") {
            if (Array.isArray(filterArray)) {
                if (filterArray.length === 0) {
                    this.setState({ department: "All Units" });
                }
                if (filterArray.length === 1) {
                    if (filterArray[0].label !== this.state.department)
                        this.setState({ department: filterArray[0].label });
                } else if (filterArray.length > 1) {
                    this.setState({
                        department: filterArray.length + " Units"
                    });
                }
            } else if (filterArray.label !== this.state.department) this.setState({ department: filterArray.label });
        } else if (filterName == "inquiryType") {
            if (Array.isArray(filterArray)) {
                if (filterArray.length === 0) {
                    this.setState({ inquiryType: "All Types" });
                }
                if (filterArray.length === 1) {
                    if (filterArray[0].label !== this.state.inquiryType)
                        this.setState({ inquiryType: filterArray[0].label });
                } else if (filterArray.length > 1) {
                    this.setState({
                        inquiryType: filterArray.length + " Types"
                    });
                }
            } else if (filterArray.label !== this.state.inquiryType) this.setState({ inquiryType: filterArray.label });
        } else if (filterName == "franchise") {
            if (Array.isArray(filterArray)) {
                if (filterArray.length === 0) {
                    this.setState({ franchise: "HQ" });
                }
                if (filterArray.length === 1) {
                    if (filterArray[0].label !== this.state.franchise)
                        this.setState({ franchise: filterArray[0].label });
                } else if (filterArray.length > 1) {
                    this.setState({
                        franchise: filterArray.length + " Franchises"
                    });
                }
            } else if (filterArray.label !== this.state.franchise) this.setState({ franchise: filterArray.label });
        }
    }

    render() {
        console.log("this.props.campaignTypeList: ", this.props.campaignTypeList);
        return (
            <>
                {this.state.searchInput === "account" && <SearchFilter module="account" className="searchPL" /> //search input for accounts page
                }
                {this.state.searchInput === "precampaign" && (
                    <div className="mr-3">
                        <SearchFilter module="precampaign" className="searchPL" />
                    </div>
                ) //search input for precampaign page
                }
                {/*<div className="subheader-action">
                    {this.props.isOpenCalendarButton && (
                        <Button
                            variant="secondary"
                            icon
                            src={toAbsoluteUrl("/media/svg/icons/General/calendar.svg")}
                            outline
                            title="Calendar"
                        />
                    )}

                    {this.props.isOpenAddTrainerButton && this.state.isTrainerEditPermission && (
                        <Button
                            variant="success"
                            icon
                            src={toAbsoluteUrl("/media/svg/icons/General/plus.svg")}
                            outline
                            onClick={() => {
                                this.props.toggleAccountEditButton(true);
                            }}
                            title="Add New Trainer"
                        />
                    )}

                    {this.state.isEditButton && !this.state.isOpenEditButton && this.state.isTrainerEditPermission && (
                        <Button
                            variant="success"
                            icon
                            src={toAbsoluteUrl("/media/svg/icons/General/edit.svg")}
                            outline
                            onClick={() => {
                                this.props.toggleEditButton(true);
                            }}
                            title="Edit"
                        />
                    )}

                    {this.state.isEditButton && this.state.isOpenEditButton && this.state.isTrainerEditPermission && (
                        <Button
                            variant="success"
                            outline
                            onClick={() => {
                                this.props.toggleEditButton(false);
                            }}
                            title="Done"
                        />
                    )}

                    {this.state.isAccountEditButton && !this.state.isOpenAccountEditButton && (
                        <Button
                            variant="success"
                            icon
                            src={toAbsoluteUrl("/media/svg/icons/General/edit.svg")}
                            outline
                            onClick={() => {
                                this.props.toggleAccountEditButton(true);
                            }}
                            title="Edit"
                        />
                    )}

                    {this.state.isAccountEditButton && this.state.isOpenAccountEditButton && (
                        <Button
                            variant="success"
                            outline
                            onClick={() => {
                                this.props.toggleAccountEditButton(false);
                            }}
                            title="Done"
                        />
                    )}

                    {this.state.isAccountMergeButton && (
                        <Button
                            variant="secondary"
                            icon
                            src={toAbsoluteUrl("/media/svg/icons/General/merge.svg")}
                            outline
                            onClick={() => {
                                this.props.toggleAccountMergeButton(true);
                            }}
                            title="Merge"
                        />
                    )}

                    {this.state.duplicateButton && (
                        <Button
                            title="Duplicate"
                            outline
                            variant="success"
                            // className="trainerFilterButtons"
                            onClick={() => {
                                this.props.setStatusDuplicateButton("duplicate");
                            }}
                        />
                        //     Duplicate
                        // </Button>
                    )}

                    {this.state.showAddSurveyButton && this.props.user.permissions.SURVEY_EDIT === 1 ? (
                        <NavLink to="/survey/add" className="cardToolbarMuted">
                            <Button title="Create New Evaluation Form" outline variant="success" />
                        </NavLink>
                    ) : (
                        <div></div>
                    )}

                    {this.state.surveyId &&
                        this.state.showEditSurveyButton &&
                        this.state.statusEditSurveyButton &&
                        this.props.user.permissions.SURVEY_EDIT === 1 ? (
                        <NavLink to={"/survey/edit/" + this.state.surveyId} className="cardToolbarMuted">
                            <Button
                                classNames=" font-weight-normal surveyInfoEditButton"
                                icon
                                src="/media/svg/popleads/edit_pen.svg"
                                variant="success"
                                title="Edit"
                                outline
                            />
                        </NavLink>
                    ) : (
                        <div></div>
                    )}

                    {this.props.user && this.props.user.permissions && (
                        <>
                            {this.state.isPreCampaignButton &&
                                this.props.user.permissions.INSERT_EDIT_PRECAMPAIGNS === 1 && (
                                    <NavLink to="/campaigns/precampaigns/add" className="cardToolbarMuted">
                                        <Button
                                            title="New Pre-Campaign"
                                            variant="success"
                                            icon
                                            src="/media/svg/popleads/plus.svg"
                                            outline
                                            className=" trainerFilterButtons buttonDesktop mx-2"
                                        />
                                        <Button
                                            title="Add"
                                            variant="primary"
                                            outline
                                            className="trainerFilterButtons buttonMobile"
                                        />
                                    </NavLink>
                                )}

                            {this.state.editMoreInfoButton &&
                                !this.state.isOpenEditMoreInfoButton &&
                                this.props.user.permissions.PRECAMPAIGN_TRAINER_EDIT === 1 && (
                                    <Button
                                        classNames=" font-weight-normal surveyInfoEditButton"
                                        icon
                                        src="/media/svg/popleads/edit_pen.svg"
                                        variant="success"
                                        title="Edit"
                                        outline
                                        onClick={() => {
                                            this.props.toggleEditMoreInfoButton(true);
                                        }}
                                    />
                                )}

                            {this.state.editMoreInfoButton &&
                                this.state.isOpenEditMoreInfoButton &&
                                this.props.user.permissions.PRECAMPAIGN_TRAINER_EDIT === 1 && (
                                    <>
                                        <Button
                                            variant="success"
                                            title="Done"
                                            outline
                                            onClick={() => {
                                                this.props.setStatusEditMoreInfoButton("done");
                                            }}
                                        />

                                        <Button
                                            variant="success"
                                            title="Cancel"
                                            outline
                                            onClick={() => {
                                                this.props.setStatusEditMoreInfoButton("cancel");
                                            }}
                                        />
                                    </>
                                )}
                        </>
                    )}
                                        </div>*/}
                <div className="subHeader-buttons d-flex flex-wrap">
                    {this.state.industryFilter && (
                        <Dropdown id="industryDropdown" className="d-none d-xl-block" align="end">
                            <Dropdown.Toggle
                                id="kt_industry_toggle"
                                variant="outline-primary"
                                className="ml-4 hide-chevron font-weight-normal">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="filters-tooltip">Industry</Tooltip>}>
                                    <span id="kt_industrySelect">
                                        <span
                                            className={this.state.industry === "All Industries" ? "disable-color" : ""}
                                            id="kt_industrySelect_title">
                                            {this.state.industry}
                                        </span>
                                    </span>
                                </OverlayTrigger>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                                <form>
                                    {/** Head */}
                                    {/* <div
                                        className="d-flex align-items-center py-5 px-8 bgi-size-cover bgi-no-repeat rounded-top"
                                        style={{
                                            backgroundColor: "#6490BF"
                                        }}>
                                        <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
                                            <span className="svg-icon svg-icon-2x svg-icon-white">
                                                <SVG
                                                    title="Industry"
                                                    src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")}
                                                />
                                            </span>
                                        </span>
                                        <h4 className="text-white m-0 flex-grow-1 mr-3">Industry</h4>
                                    </div> */}
                                    <div className="navi navi-hover">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        {/* <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                            <i className="flaticon2-user primary-color-important"></i>
                                                        </div> */}
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <IndustryFilter />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}

                    {this.state.trainerFilter && (
                        <Dropdown id="trainerDropdown" className="d-none d-lg-block" align="end">
                            <Dropdown.Toggle
                                id="kt_trainer_toggle"
                                variant="outline-primary"
                                className="ml-4 hide-chevron font-weight-normal">
                                <span id="kt_trainerSelect">
                                    <span
                                        className={this.state.trainer === "All Trainers" ? "disable-color" : ""}
                                        title="Select Trainer"
                                        id="kt_trainerSelect_title">
                                        {this.state.trainer}
                                    </span>
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                                <form>
                                    <div className="navi navi-hover">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <TrainerFilter isMultiSelect={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}

                    {this.state.campaignTypeListFilter && (
                        <Dropdown id="campaignTypeListDropdown" className="d-none d-lg-block" align="end">
                            <Dropdown.Toggle
                                id="kt_campaignTypeList_toggle"
                                variant="outline-primary"
                                className="ml-4 hide-chevron font-weight-normal">
                                <span id="kt_campaignTypeListSelect">
                                    <span
                                        className={
                                            this.state.campaignTypeList === "All Campaign Types" ? "disable-color" : ""
                                        }
                                        title="Select Campaign Type"
                                        id="kt_campaignTypeListSelect_title">
                                        {this.state.campaignTypeList}
                                    </span>
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                                <form>
                                    <div className="navi navi-hover">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <CampaignTypeFilter isMultiSelect={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}

                    {this.state.typeFilter && (
                        <Dropdown id="typeDropdown" className="d-none d-xl-block" align="end">
                            <Dropdown.Toggle
                                id="kt_type_toggle"
                                variant="outline-primary"
                                className="ml-4 hide-chevron font-weight-normal">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="filters-tooltip">Type</Tooltip>}>
                                    <span id="kt_typeSelect">
                                        <span
                                            className={this.state.type === "All Types" ? "disable-color" : ""}
                                            id="kt_typeSelect_title">
                                            {this.state.type}
                                        </span>
                                        {/*<span className="text-primary font-weight-bold mr-2" id="kt_dashboard_daterangepicker_date">Aug 16</span>*/}
                                    </span>
                                </OverlayTrigger>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-xl">
                                <form>
                                    {/** Head */}
                                    {/* <div
                                        className="d-flex align-items-center py-5 px-8 bgi-size-cover bgi-no-repeat rounded-top"
                                        style={{
                                            backgroundColor: "#6490BF"
                                        }}>
                                        <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
                                            <span className="svg-icon svg-icon-2x svg-icon-white">
                                                <SVG
                                                    title="Type"
                                                    src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")}
                                                />
                                            </span>
                                        </span>
                                        <h4 className="text-white m-0 flex-grow-1 mr-3">Type</h4>
                                    </div> */}
                                    <div className="navi navi-hover">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        {/* <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                            <i className="flaticon2-user primary-color-important"></i>
                                                        </div> */}
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <TypeFilter />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}

                    {this.state.employeeFilter &&
                        (!this.state.franchiseFilter ||
                            (this.state.franchiseFilter &&
                                (!this.props.selectedFranchise || this.props.selectedFranchise.length === 0))) && (
                            <Dropdown id="employeeDropdown" className="d-none d-lg-block" align="end">
                                <Dropdown.Toggle
                                    id="kt_employee_toggle"
                                    variant="outline-primary"
                                    className="ml-4 hide-chevron font-weight-normal">
                                    <span id="kt_employeeSelect">
                                        <span
                                            className={this.state.employee === "All Employees" ? "disable-color" : ""}
                                            title="Select Employee"
                                            id="kt_employeeSelect_title">
                                            {this.state.employee}
                                        </span>
                                        {/*<span className="text-primary font-weight-bold mr-2" id="kt_dashboard_daterangepicker_date">Aug 16</span>*/}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                                    <form>
                                        {/** Head */}
                                        {/* <div
                                            className="d-flex align-items-center py-5 px-8 bgi-size-cover bgi-no-repeat rounded-top"
                                            style={{
                                                backgroundColor: "#6490BF"
                                            }}>
                                            <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
                                                <span className="svg-icon svg-icon-2x svg-icon-white">
                                                    <SVG
                                                        title="Employee"
                                                        src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")}
                                                    />
                                                </span>
                                            </span>
                                            <h4 className="text-white m-0 flex-grow-1 mr-3">Employee</h4>
                                        </div> */}
                                        <div className="navi navi-hover">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <span className="navi-item">
                                                        <div className="navi-link">
                                                            {/* <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                                <i className="flaticon2-user primary-color-important"></i>
                                                            </div> */}
                                                            <div className="navi-text">
                                                                <div className="font-weight-bold">
                                                                    <EmployeeFilter isMultiSelect={true} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}

                    {this.state.departmentFilter && (
                        <Dropdown id="departmentDropdown" className="d-none d-lg-block" align="end">
                            <Dropdown.Toggle
                                id="kt_department_toggle"
                                variant="outline-primary"
                                className="ml-4 hide-chevron font-weight-normal">
                                <span
                                    id="kt_dashboard_daterangepicker2"
                                    data-toggle="tooltip"
                                    title="Select Unit"
                                    data-placement="left">
                                    <span
                                        className={this.state.department === "All Units" ? "disable-color " : ""}
                                        id="kt_dashboard_daterangepicker_title2">
                                        {this.state.department}
                                    </span>
                                    {/*<span className="text-primary font-weight-bold mr-2" id="kt_dashboard_daterangepicker_date">Aug 16</span>*/}
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                                <form>
                                    {/** Head */}
                                    {/* <div
                                        className="d-flex align-items-center py-5 px-8 bgi-size-cover bgi-no-repeat rounded-top"
                                        style={{
                                            backgroundColor: "#6490BF"
                                        }}>
                                        <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
                                            <span className="svg-icon svg-icon-2x svg-icon-white">
                                                <SVG
                                                    title="Unit"
                                                    src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")}
                                                />
                                            </span>
                                        </span>
                                        <h4 className="text-white m-0 flex-grow-1 mr-3">Unit</h4>
                                    </div> */}
                                    <div className="navi navi-hover">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        {/* <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                            <i className="flaticon2-user primary-color-important"></i>
                                                        </div> */}
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <DepartmentFilter isMultiSelect={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}

                    {(this.state.dateFilter || this.state.dateFilterSurvey) && (
                        <Dropdown id="datePickerDropdown" className="d-none d-lg-block" align="end">
                            <Dropdown.Toggle
                                id="kt_filter_toggle"
                                variant="outline-primary"
                                className="ml-4 hide-chevron font-weight-normal">
                                <span
                                    id="kt_dashboard_daterangepicker3"
                                    data-toggle="tooltip"
                                    title="Select dashboard daterange"
                                    data-placement="left">
                                    <span
                                        className={(this.state.date || this.state.dateSurvey) === "All Time" ? "disable-color" : ""}
                                        id="kt_dashboard_daterangepicker_title3">
                                        {this.state.dateFilter ? this.state.date : this.state.dateSurvey}
                                    </span>

                                    {/*<span className="text-primary font-weight-bold mr-2" id="kt_dashboard_daterangepicker_date">Aug 16</span>*/}
                                </span>
                            </Dropdown.Toggle>
                            <div className="filter-wrapper">
                                <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl dropdown-menu-withDateRangePicker">
                                    <form>
                                        {/** Head */}
                                        {/* <div
                                        className="d-flex align-items-center py-5 px-8 bgi-size-cover bgi-no-repeat rounded-top"
                                        style={{
                                            backgroundColor: "#6490BF"
                                        }}>
                                        <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
                                            <span className="svg-icon svg-icon-2x svg-icon-white">
                                                <SVG
                                                    title="Date"
                                                    src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")}
                                                />
                                            </span>
                                        </span>
                                        <h4 className="text-white m-0 flex-grow-1 mr-3">Dates</h4>
                                    </div> */}
                                        <div className="navi navi-hover">
                                            <span className="navi-item">
                                                <div className="navi-link">
                                                    {/* <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                    <i className="flaticon2-calendar-1 primary-color-important"></i>
                                                </div> */}
                                                    <div className="navi-text">
                                                        <div className="font-weight-bold">
                                                            <DateFilter />
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </form>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>
                    )}

                    {this.state.inquiryTypeFilter && (
                        <Dropdown id="inquiryTypeDropdown" className="d-none d-lg-block" align="end">
                            <Dropdown.Toggle
                                id="kt_inquiry_type_toggle"
                                variant="outline-primary"
                                className="ml-4 hide-chevron font-weight-normal">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="filters-tooltip">Inquiry Type</Tooltip>}>
                                    <span
                                        id="kt_dashboard_inquiry_type"
                                        data-toggle="tooltip"
                                        title="Select Inquiry Type"
                                        data-placement="left">
                                        <span
                                            className={this.state.inquiryType === "All Types" ? "disable-color" : ""}
                                            id="kt_dashboard_inquiry_type_title2">
                                            {this.state.inquiryType}
                                        </span>
                                    </span>
                                </OverlayTrigger>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                                <form>
                                    {/** Head */}
                                    {/* <div
                                        className="d-flex align-items-center py-5 px-8 bgi-size-cover bgi-no-repeat rounded-top"
                                        style={{
                                            backgroundColor: "#6490BF"
                                        }}>
                                        <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
                                            <span className="svg-icon svg-icon-2x svg-icon-white">
                                                <SVG
                                                    title="Date"
                                                    src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")}
                                                />
                                            </span>
                                        </span>
                                        <h4 className="text-white m-0 flex-grow-1 mr-3">Inquiry Type</h4>
                                    </div> */}
                                    <div className="navi navi-hover">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        {/* <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                            <i className="flaticon2-notepad primary-color-important"></i>
                                                        </div> */}
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <InquiryTypeFilter isMultiSelect={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}

                    {this.state.franchiseFilter && this.props.user.company.headquartersId === null && (
                        <Dropdown id="inquiryTypeDropdown" className="d-none d-lg-block" align="end">
                            <Dropdown.Toggle
                                id="kt_inquiry_type_toggle"
                                variant="outline-primary"
                                className="ml-4 hide-chevron font-weight-normal">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="filters-tooltip">Franchises</Tooltip>}>
                                    <span
                                        id="kt_dashboard_inquiry_type"
                                        data-toggle="tooltip"
                                        title="Select Inquiry Type"
                                        data-placement="left">
                                        <span
                                            className={this.state.franchise === "HQ" ? "disable-color" : ""}
                                            id="kt_dashboard_inquiry_type_title2">
                                            {this.state.franchise}
                                        </span>
                                    </span>
                                </OverlayTrigger>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                                <form>
                                    {/** Head */}
                                    {/* <div
                                        className="d-flex align-items-center py-5 px-8 bgi-size-cover bgi-no-repeat rounded-top"
                                        style={{
                                            backgroundColor: "#6490BF"
                                        }}>
                                        <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
                                            <span className="svg-icon svg-icon-2x svg-icon-white">
                                                <SVG
                                                    title="Date"
                                                    src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")}
                                                />
                                            </span>
                                        </span>
                                        <h4 className="text-white m-0 flex-grow-1 mr-3">Franchise</h4>
                                    </div> */}
                                    <div className="navi navi-hover">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        {/* <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                            <i className="flaticon2-notepad primary-color-important"></i>
                                                        </div> */}
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <FranchiseFilter isMultiSelect={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
                {(this.state.inquiryTypeFilter ||
                    this.state.franchiseFilter ||
                    this.state.employeeFilter ||
                    this.state.departmentFilter ||
                    this.state.dateFilter ||
                    this.state.industryFilter ||
                    this.state.trainerFilter ||
                    this.state.campaignTypeListFilter ||
                    this.state.typeFilter) && (
                        // <FilterDrawer />
                        <Dropdown
                            align="end"
                        // onToggle={isOpen => {
                        //     this.setState({ isToggle: isOpen });
                        // }}
                        // show={this.state.isToggle}
                        >
                            <Dropdown.Toggle
                                id="kt_filter_toggle"
                                variant="secondary"
                                className="ml-0 ml-sm-4 hide-chevron font-weight-normal p-0">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="filters-tooltip">Filters</Tooltip>}>
                                    <span className="svg-icon2">
                                        <SVG title="Filters" src={toAbsoluteUrl("/media/svg/icons/Text/Filters2.svg")} />
                                    </span>
                                </OverlayTrigger>
                            </Dropdown.Toggle>
                            <div className="filter-wrapper">
                                {/* {this.state.isToggle && <div className="overlay"></div>} */}
                                <Dropdown.Menu className="p-0 dropdown-menu-anim-up dropdown-menu-xl dropdown-menu-withDateRangePicker">
                                    <form>
                                        {/* <div
                                            className="head d-flex align-items-center py-5 px-8 bgi-size-cover bgi-no-repeat rounded-top"
                                            style={{
                                                backgroundColor: "#6490BF"
                                            }}>
                                            <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
                                                <span className="svg-icon svg-icon-2x svg-icon-white">
                                                    <SVG
                                                        title="Date"
                                                        src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")}
                                                    />
                                                </span>
                                            </span>
                                            <h4 className="text-white m-0 flex-grow-1 mr-3">Filters</h4>
                                        </div> */}
                                        {/** Head END*/}
                                        <div className="navi navi-hover">
                                            <div className="row">
                                                {this.props.industryFilter && (
                                                    <div className="col-md-6 col-sm-12 pl-md-0">
                                                        <span className="navi-item">
                                                            <div className="navi-link">
                                                                {/* <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                                    <i className="flaticon2-world primary-color-important"></i>
                                                                </div> */}
                                                                <div className="navi-text">
                                                                    <div className="font-weight-bold">
                                                                        <IndustryFilter />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                )}

                                                {this.props.typeFilter && (
                                                    <div className="col-md-6 col-sm-12 pr-md-0">
                                                        <span className="navi-item">
                                                            <div className="navi-link">
                                                                {/* <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                                    <i className="flaticon2-tag primary-color-important"></i>
                                                                </div> */}
                                                                <div className="navi-text">
                                                                    <div className="font-weight-bold">
                                                                        <TypeFilter />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                )}

                                                {this.props.employeeFilter && (
                                                    <div className="col-md-6 col-sm-12">
                                                        <span className="navi-item">
                                                            <div className="navi-link">
                                                                <div className="navi-text">
                                                                    <div className="font-weight-bold">
                                                                        <EmployeeFilter isMultiSelect={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                )}

                                                {this.props.trainerFilter && (
                                                    <div className="col-md-6 col-sm-12">
                                                        <span className="navi-item">
                                                            <div className="navi-link">
                                                                <div className="navi-text">
                                                                    <div className="font-weight-bold">
                                                                        <TrainerFilter isMultiSelect={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                )}

                                                {this.props.campaignTypeListFilter && (
                                                    <div className="col-md-6 col-sm-12">
                                                        <span className="navi-item">
                                                            <div className="navi-link">
                                                                <div className="navi-text">
                                                                    <div className="font-weight-bold">
                                                                        <CampaignTypeFilter isMultiSelect={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                )}
                                                {this.props.departmentFilter && (
                                                    <div className="col-md-6 col-sm-12">
                                                        <span className="navi-item">
                                                            <div className="navi-link">
                                                                <div className="navi-text">
                                                                    <div className="font-weight-bold">
                                                                        <DepartmentFilter isMultiSelect={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                )}

                                                {this.state.inquiryTypeFilter && (
                                                    <div className="col-md-6 col-sm-12">
                                                        <span className="navi-item">
                                                            <div className="navi-link">
                                                                {/* <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                                    <i className="flaticon2-notepad primary-color-important"></i>
                                                                </div> */}
                                                                <div className="navi-text">
                                                                    <div className="font-weight-bold">
                                                                        <InquiryTypeFilter isMultiSelect={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                )}

                                                {this.state.franchiseFilter && (
                                                    <div className="col-md-6 col-sm-12">
                                                        <span className="navi-item">
                                                            <div className="navi-link">
                                                                {/* <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                                    <i className="flaticon2-notepad primary-color-important"></i>
                                                                </div> */}
                                                                <div className="navi-text">
                                                                    <div className="font-weight-bold">
                                                                        <FranchiseFilter isMultiSelect={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>

                                            {this.props.dateFilter && (
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <DateFilter />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            )}
                                            {/* <div className="d-flex align-items-end justify-content-end mt-4">
                                            <Button
                                                title={"Close"}
                                                variant="disabledbtn"
                                                outline={false}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({ isToggle: false });
                                                }}
                                                className="mr-2"
                                            />
                                        </div> */}
                                        </div>
                                    </form>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>
                    )}
                {this.props.gridOrTableFilter && (
                    <div className="btn-group btn-group-toggle btn-group-subheader" data-toggle="buttons">
                        <label
                            className={
                                this.state.gridOrTable === "grid" ? "selectGridBtn ml-2 active" : "selectGridBtn ml-2"
                            }>
                            <input
                                type="radio"
                                name="gridOrTable"
                                id="option1"
                                onChange={() => this.props.setGridOrTable("grid")}
                                autoComplete="off"
                                checked={this.state.gridOrTable === "grid" ? true : false}
                            />
                            {/* <i className="flaticon2-grids"></i> */}
                            <SVG title="Clone" src={toAbsoluteUrl("/media/svg/popleads/select_grid_icon.svg")} />
                        </label>
                        <label
                            className={
                                this.state.gridOrTable === "table" ? "selectTableBtn  active" : "selectTableBtn "
                            }>
                            <input
                                type="radio"
                                name="gridOrTable"
                                id="option2"
                                onChange={() => this.props.setGridOrTable("table")}
                                autoComplete="off"
                                checked={this.state.gridOrTable === "table" ? true : false}
                            />
                            {/* <i className="flaticon2-list-1"></i> */}
                            <SVG title="Clone" src={toAbsoluteUrl("/media/svg/popleads/select_list_icon.svg")} />
                        </label>
                    </div>
                )}

                <div className="subheader-action">
                    {this.props.isOpenCalendarButton && (
                        <NavLink to="/trainers/calendar/" className="cardToolbarMuted">
                            <Button
                                variant="secondary"
                                icon
                                src={toAbsoluteUrl("/media/svg/icons/General/calendar.svg")}
                                outline
                                title="Calendar"
                            />
                        </NavLink>
                    )}

                    {this.props.isOpenAddTrainerButton && this.state.isTrainerEditPermission && (
                        <Button
                            variant="success"
                            icon
                            src={toAbsoluteUrl("/media/svg/icons/General/plus.svg")}
                            outline
                            onClick={() => {
                                this.props.toggleAccountEditButton(true);
                            }}
                            title="Add New Trainer"
                        />
                    )}

                    {this.state.isEditButton && !this.state.isOpenEditButton && this.state.isTrainerEditPermission && (
                        <Button
                            variant="success"
                            icon
                            src={toAbsoluteUrl("/media/svg/icons/General/edit.svg")}
                            outline
                            onClick={() => {
                                this.props.toggleEditButton(true);
                            }}
                            title="Edit"
                        />
                    )}

                    {this.state.isEditButton && this.state.isOpenEditButton && this.state.isTrainerEditPermission && (
                        <Button
                            variant="success"
                            outline
                            onClick={() => {
                                this.props.toggleEditButton(false);
                            }}
                            title="Done"
                        />
                    )}

                    {this.state.isAccountEditButton && !this.state.isOpenAccountEditButton && (
                        <Button
                            variant="success"
                            icon
                            src={toAbsoluteUrl("/media/svg/icons/General/edit.svg")}
                            outline
                            onClick={() => {
                                this.props.toggleAccountEditButton(true);
                            }}
                            title="Edit"
                        />
                    )}

                    {this.state.isAccountEditButton && this.state.isOpenAccountEditButton && (
                        <Button
                            variant="success"
                            outline
                            onClick={() => {
                                this.props.toggleAccountEditButton(false);
                            }}
                            title="Done"
                        />
                    )}

                    {this.state.isAccountMergeButton && (
                        <Button
                            variant="secondary"
                            icon
                            src={toAbsoluteUrl("/media/svg/icons/General/merge.svg")}
                            outline
                            onClick={() => {
                                this.props.toggleAccountMergeButton(true);
                            }}
                            title="Merge"
                        />
                    )}

                    {this.state.isCourseEditButton && 
                        this.props.user && 
                        this.props.user.permissions &&
                        this.props.user.permissions.CAMPAIGNTEMPLATE_MANAGER === 1 &&(
                        <>
                            {!this.state.isOpenCourseEditButton &&
                                <Button
                                    variant="success"
                                    icon
                                    src={toAbsoluteUrl("/media/svg/icons/General/edit.svg")}
                                    outline
                                    onClick={() => {
                                        this.props.toggleCourseEditButton(true);
                                    }}
                                    title="Edit"
                                />
                            }
                            {this.state.isOpenCourseEditButton && (
                                <>
                                    <Select
                                        placeholder={"Language"}
                                        className="customSelectInput--no-wrap "
                                        value={this.props.language}
                                        defaultValue={{ value: "en", label: "English" }}
                                        isSearchable={false}
                                        options={[{ value: "en", label: "English" }, { value: "ar", label: "Arabic" }]}
                                        name="language"
                                        onChange={selected => {
                                            this.props.setLanguage(selected);
                                        }}
                                    />
                                    <Button
                                        variant="secondary"
                                        outline
                                        onClick={() => {
                                            this.props.toggleCourseEditButton(false);
                                        }}
                                        title="Done"
                                    />
                                </>
                            )
                            }
                        </>
                    )}

                    {this.state.duplicateButton && (
                        <Button
                            title="Duplicate"
                            outline
                            variant="success"
                            // className="trainerFilterButtons"
                            onClick={() => {
                                this.props.setStatusDuplicateButton("duplicate");
                            }}
                        />
                        //     Duplicate
                        // </Button>
                    )}

                    {this.state.showAddSurveyButton && this.props.user.permissions.SURVEY_EDIT === 1 ? (
                        <NavLink to="/survey/add" className="cardToolbarMuted">
                            <Button title="Create New Evaluation Form" outline variant="success" />
                        </NavLink>
                    ) : (
                        <div></div>
                    )}

                    {this.state.surveyId &&
                    this.state.showEditSurveyButton &&
                    this.state.statusEditSurveyButton &&
                    this.props.user.permissions.SURVEY_EDIT === 1 ? (
                        <NavLink to={"/survey/edit/" + this.state.surveyId} className="cardToolbarMuted">
                            <Button
                                classNames=" font-weight-normal surveyInfoEditButton"
                                icon
                                src="/media/svg/popleads/edit_pen.svg"
                                variant="success"
                                title="Edit"
                                outline
                            />
                        </NavLink>
                    ) : (
                        <div></div>
                    )}

                    {this.props.isCoursesButton &&
                        this.props.user && 
                        this.props.user.permissions &&
                        this.props.user.permissions.CAMPAIGNTEMPLATE_MANAGER === 1 && 
                        <NavLink to="/campaigns/courses/add" className="cardToolbarMuted">
                            <Button
                                title="Add New Course"
                                variant="success"
                                icon
                                src="/media/svg/popleads/plus.svg"
                                outline
                                className="d-none d-sm-flex buttonDesktop mx-2"
                            />
                            {/* <Button
                                variant="success"
                                icon
                                src="/media/svg/popleads/plus.svg"
                                outline
                                className=" trainerFilterButtons buttonMobile"
                            /> */}
                        </NavLink>
                    }

                    {this.props.user && this.props.user.permissions && (
                        <>
                            {this.state.isPreCampaignButton &&
                                this.props.user.permissions.INSERT_EDIT_PRECAMPAIGNS === 1 && (
                                    <NavLink to="/campaigns/precampaigns/add" className="cardToolbarMuted">
                                        <Button
                                            title="New Pre-Campaign"
                                            variant="success"
                                            icon
                                            src="/media/svg/popleads/plus.svg"
                                            outline
                                            className=" trainerFilterButtons buttonDesktop mx-2"
                                        />
                                        <Button
                                            title="Add"
                                            variant="primary"
                                            outline
                                            className="trainerFilterButtons buttonMobile"
                                        />
                                    </NavLink>
                                )}

                            {this.state.editMoreInfoButton &&
                                !this.state.isOpenEditMoreInfoButton &&
                                this.props.user.permissions.PRECAMPAIGN_TRAINER_EDIT === 1 && (
                                    <Button
                                        classNames=" font-weight-normal surveyInfoEditButton"
                                        icon
                                        src="/media/svg/popleads/edit_pen.svg"
                                        variant="success"
                                        title="Edit"
                                        outline
                                        onClick={() => {
                                            this.props.toggleEditMoreInfoButton(true);
                                        }}
                                    />
                                )}

                            {this.state.editMoreInfoButton &&
                                this.state.isOpenEditMoreInfoButton &&
                                this.props.user.permissions.PRECAMPAIGN_TRAINER_EDIT === 1 && (
                                    <>
                                        <Button
                                            variant="success"
                                            title="Done"
                                            outline
                                            onClick={() => {
                                                this.props.setStatusEditMoreInfoButton("done");
                                            }}
                                        />

                                        <Button
                                            variant="success"
                                            title="Cancel"
                                            outline
                                            onClick={() => {
                                                this.props.setStatusEditMoreInfoButton("cancel");
                                            }}
                                        />
                                    </>
                                )}
                        </>
                    )}

                    {this.props.isOpenAddEventButton && (
                        <NavLink to="/trainers/calendar/addnewevent/" className="cardToolbarMuted">
                            <Button
                                variant="success"
                                icon
                                src={toAbsoluteUrl("/media/svg/icons/General/plus.svg")}
                                outline
                                title="Add New Event"
                            />
                        </NavLink>
                    )}
                </div>
                <NewFilter />
                {this.props.gridOrTableFilter_precampaign && (
                    <div
                        className="btn-group btn-group-toggle btn-group-subheader gridOrTableFilter_precampaign"
                        data-toggle="buttons">
                        <label
                            className={
                                this.props.gridOrTable_precampaign === "grid" ? "selectGridBtn active" : "selectGridBtn"
                            }>
                            <input
                                type="radio"
                                name="gridOrTable"
                                id="option1"
                                onChange={() => this.props.gridOrTableButton("grid")}
                                autoComplete="off"
                                checked={this.props.gridOrTable_precampaign === "grid" ? true : false}
                            />
                            {/* <i className="flaticon2-grids"></i> */}
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/select_grid_icon.svg")} />
                        </label>
                        <label
                            className={
                                this.props.gridOrTable_precampaign === "table"
                                    ? "selectTableBtn active"
                                    : "selectTableBtn"
                            }>
                            <input
                                type="radio"
                                name="gridOrTable"
                                id="option2"
                                onChange={() => this.props.gridOrTableButton("table")}
                                autoComplete="off"
                                checked={this.props.gridOrTable_precampaign === "table" ? true : false}
                            />
                            {/* <i className="flaticon2-list-1"></i> */}
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/select_list_icon.svg")} />
                        </label>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedEmployee: state.reports.selectedEmployee,
        selectedTrainer: state.reports.selectedTrainer,
        selectedCampaignTypeList: state.reports.selectedCampaignTypeList,
        selectedDepartment: state.reports.selectedDepartment,
        selectedDate: state.reports.selectedDate,
        selectedDateSurvey: state.reports.selectedDateSurvey,
        employeeFilter: state.app.employeeFilter,
        departmentFilter: state.app.departmentFilter,
        trainerFilter: state.app.trainerFilter,
        campaignTypeListFilter: state.app.campaignTypeListFilter,
        dateFilter: state.app.dateFilter,
        dateFilterSurvey: state.app.dateFilterSurvey,
        gridOrTable: state.accounts.gridOrTable,
        gridOrTableFilter: state.accounts.gridOrTableFilter,
        selectedType: state.accounts.selectedType,
        selectedIndustry: state.accounts.selectedIndustry,
        selectedInquiryType: state.reports.selectedInquiryType,
        selectedFranchise: state.reports.selectedFranchise,
        typeFilter: state.app.typeFilter,
        industryFilter: state.app.industryFilter,
        inquiryTypeFilter: state.app.inquiryTypeFilter,
        franchiseFilter: state.app.franchiseFilter,
        searchInput: state.app.searchInput,
        isOpenAddTrainerButton: state.training.isOpenAddTrainerButton,
        isOpenAddEventButton: state.training.isOpenAddEventButton,
        isOpenCalendarButton: state.training.isOpenCalendarButton,
        isEditButton: state.training.isEditButton,
        isOpenEditButton: state.training.isOpenEditButton,
        user: state.auth.user,
        isAccountEditButton: state.accounts.isAccountEditButton,
        isAccountMergeButton: state.accounts.isAccountMergeButton,
        isOpenAccountEditButton: state.accounts.isOpenAccountEditButton,
        gridOrTable_precampaign: state.campaigns.gridOrTable,
        gridOrTableFilter_precampaign: state.campaigns.gridOrTableFilter,
        searchInput_precampaign: state.campaigns.searchInput_precampaign,
        isPreCampaignButton: state.campaigns.isPreCampaignButton,
        editMoreInfoButton: state.campaigns.editMoreInfoButton,
        isOpenEditMoreInfoButton: state.campaigns.isOpenEditMoreInfoButton,
        duplicateButton: state.campaigns.duplicateButton,
        selectedLength: state.campaigns.selectedLength,
        isCourseEditButton: state.campaigns.isCourseEditButton,
        isOpenCourseEditButton: state.campaigns.isOpenCourseEditButton,
        showAddSurveyButton: state.survey.showAddSurveyButton,
        showEditSurveyButton: state.survey.showEditSurveyButton,
        statusEditSurveyButton: state.survey.statusEditSurveyButton,
        surveyId: state.survey.surveyId,
        isCoursesButton: state.campaigns.isCoursesButton,
        language: state.campaigns.language
    };
};

const mapDispatchToProps = {
    ...reportsRedux.actions,
    ...appRedux.actions,
    ...accountsRedux.actions,
    ...trainingRedux.actions,
    ...campaignsRedux.actions,
    ...surveyRedux.actions
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
