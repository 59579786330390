/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import * as campaignsRedux from "../../../app/popleads/modules/campaigns/redux/campaignsRedux"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import Button from './../../../app/popleads/components/Button';

function DropdownPopleads_precampaign(props) {
    const selectedLength = props.selectedLength;
    const permissions = (props.user && props.user.permissions) ? props.user.permissions : null;

    return (<>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover">
            {permissions.LAUNCH_CLONE_PRECAMPAIGNS === 1 &&
                <li className="navi-item mx-3 mt-5">
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-launch`}>
                                {selectedLength < 1 ? <strong>{"Please select at least 1 pre-campaign in order to launch.."}</strong> : <strong>Launch</strong>}
                            </Tooltip>
                        }
                    >
                        <span>
                            <div tabIndex="0" >
                                <Button 
                                    variant="success"
                                    icon
                                    outline
                                    title="Launch"
                                    src="/media/svg/popleads/launch_icon.svg"
                                    disabled={selectedLength < 1} 
                                    className="btn btn-primary btn-md btn-block launchButton mb-5" 
                                    onClick={() => {
                                        props.setStatusLaunchModal(true);
                                }} />
                                    {/* <img src='/media/shuttle.png' />
                                    Launch
                                </Button> */}
                            </div>
                        </span>
                    </OverlayTrigger>
                </li>
            }
            {permissions.LAUNCH_CLONE_PRECAMPAIGNS === 1 &&
                <li className="navi-item mx-3">
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-clone`}>
                                {selectedLength < 1 ? <strong>{"Please select at least 1 pre-campaign in order to clone.."}</strong> : <strong> Clone </strong>}
                            </Tooltip>
                        }
                    >
                        <span>
                            <div tabIndex="0" >
                                <Button
                                    title="Clone"
                                    variant="secondary"
                                    outline
                                    icon
                                    src="/media/svg/popleads/clone_icon.svg"
                                    disabled={selectedLength < 1} 
                                    className=" btn-md btn-block cloneButton mb-5" 
                                    onClick={() => {
                                        props.setStatusCloneModal(true);
                                }} />
                                    {/* <span className="svg-icon svg-icon-x svg-icon-lightgrey mr-5">
                                        <SVG title="Clone" src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")} />
                                    </span>
                                    Clone
                                </Button> */}
                            </div>
                        </span>
                    </OverlayTrigger>

                </li>
            }

            {permissions.DELETE_PRECAMPAIGN === 1 &&
                <li className="navi-item mx-3">
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-delete`}>
                                {selectedLength < 1 ? <strong>{"Please select at least 1 pre-campaign in order to delete.."}</strong> : <strong> Delete </strong>}
                            </Tooltip>
                        }
                    >
                        <span>
                            <div tabIndex="0" >
                                <Button 
                                    title="Delete"
                                    outline 
                                    icon 
                                    variant="danger" 
                                    src="/media/svg/popleads/delete.svg" 
                                    type="button"
                                    disabled={selectedLength < 1} 
                                    className="btn btn-primary btn-md btn-block  mb-5" 
                                    onClick={() => {
                                    props.setStatusDeleteModal(true);
                                    }} />
                                    {/* <span className="svg-icon svg-icon-x svg-icon-white mr-5">
                                        <SVG title="Delete" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                    </span>
                                    Delete
                                </Button> */}
                            </div>
                        </span>
                    </OverlayTrigger>

                </li>
            }

            {/*(permissions.INSERT_EDIT_PRECAMPAIGNS === 1 || permissions.PRECAMPAIGN_TRAINER_EDIT === 1) &&
                <li className="navi-item">
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-edit`}>
                                <strong>Edit</strong>
                            </Tooltip>
                        }
                    >
                        <span>
                            <div tabIndex="0" >
                                {!props.editInTable ?
                                    <button type="button" className="btn btn-primary btn-md btn-block editButton" onClick={() => {
                                        props.setEditInTable(true);
                                    }}>
                                        <span className="svg-icon svg-icon-x svg-icon-lightgrey mr-5">
                                            <SVG title="Edit" src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                                        </span>
                                        Edit
                                    </button> :
                                    <button type="button" className="btn btn-primary btn-md btn-block editButton" onClick={() => {
                                        props.setEditInTable(false);
                                    }}>
                                        Complete Edit
                                    </button>
                                }
                            </div>
                        </span>
                    </OverlayTrigger>

                </li>
            */}
        </ul>
        {/*end::Navigation*/}
    </>);
}

const mapStateToProps = (state) => {
    return {
        selectedLength: state.campaigns.selectedLength,
        editInTable: state.campaigns.editInTable,
        user: state.auth.user
    };
};

const mapDispatchToProps = {
    ...campaignsRedux.actions
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownPopleads_precampaign);
