export const actionTypes = {
    ToggleAddPreCampaignButton: "[Action] Toggle Add Pre-Campaign Button",
    ToggleAddCoursesButton: "[Action] Toggle Add Course Button",
    GridOrTableButton: "[Action] Toggle Grid or Table",
    GridOrTableFilter: "[Action] Filter Grid or Table",
    Search: "[Action] Search",
    SelectAllButton: "[Action] Select All Button",
    IsSelectedAll: "[Action] Is Selected All",
    QuickActionsButton: "[Action] Quick Actions Button",
    EditMoreInfoButton: "[Action] Edit More Info Button",
    IsOpenEditMoreInfoButton: "[Action] Toggle More Info Button",
    StatusEditMoreInfoButton: "[Action] Status More Info Button",
    DuplicateButton: "[Action] Duplicate Button",
    StatusDuplicateButton: "[Action] Status Duplicate Button",
    StatusCloneModal: "[Action] Status Clone Modal",
    StatusDeleteModal: "[Action] Status Delete Modal",
    StatusLaunchModal: "[Action] Status Launch Modal",
    SelectedLength: "[Action] Selected Length",
    SearchFilter: "[Action] Search Filter",
    ShowSearchFilter: "[Action] Set Search Filter",
    PreCampaignList: "[Action] PreCampaign List",
    PreCampaignListCountTotal: "[Action] PreCampaign List Count Total",
    PreCampaignListPageNumber: "[Action] PreCampaign List Page Number",
    PreCampaignListSelectedList: "[Action] PreCampaign List Selected List",
    EditInTable: "[Action] PreCampaign Edit In Table",
    IsCourseEditButtonExist: "[Action] Is Course Edit Button",
    ToggleCourseEditButton: "[Action] Toggle Course Edit Button",
    SetSubheader: "[Action] Set Subheader",
    SetLanguage: "[Action] Language",
    SetCourseSlug: "[Action] Course Slug",
    SetSelectedCampaign: "[Action] Set selected Campaign",
    SetCourseStatus: "[Action] Course Status",
    SetCourseDetailLoading: "[Action] Course Detail Loading",
};

const initialReportsState = {
    isPreCampaignButton: false,
    isCoursesButton: false,
    gridOrTable: "table",
    gridOrTableFilter: false,
    searchInput_precampaign: "",
    selectAllButton: false,
    isSelectedAll: false,
    quickActionsButton: false,
    editMoreInfoButton: false,
    isOpenEditMoreInfoButton: false,
    statusEditMoreInfoButton: undefined,
    duplicateButton: false,
    statusDuplicateButton: undefined,
    statusCloneModal: undefined,
    statusDeleteModal: undefined,
    statusLaunchModal: undefined,
    selectedLength: 0,
    searchFilter: undefined,
    showSearchFilter: undefined,
    preCampaignList: undefined,
    preCampaignListCountTotal: 0,
    preCampaignListPageNumber: 0,
    preCampaignListSelectedList: undefined,
    editInTable: false,
    isCourseEditButton: false,
    isOpenCourseEditButton: false,
    subheader: undefined,
    language: { value: "en", label: "English" },
    courseSlug: "",
    selectedCampaign: null,
    courseStatus: undefined,
    isCourseDetailLoading: false,
};

export const reducer = (state = initialReportsState, action) => {
    switch (action.type) {
        case actionTypes.ToggleAddPreCampaignButton: {
            const { isPreCampaignButton } = action.payload;
            return { ...state, isPreCampaignButton };
        }
        case actionTypes.ToggleAddCoursesButton: {
            const { isCoursesButton } = action.payload;
            return { ...state, isCoursesButton };
        }

        case actionTypes.GridOrTableButton: {
            const { gridOrTable } = action.payload;
            return { ...state, gridOrTable };
        }

        case actionTypes.GridOrTableFilter: {
            const { gridOrTableFilter } = action.payload;
            return { ...state, gridOrTableFilter };
        }
        case actionTypes.Search: {
            const { searchInput_precampaign } = action.payload;
            return { ...state, searchInput_precampaign };
        }

        case actionTypes.SelectAllButton: {
            const { selectAllButton } = action.payload;
            return { ...state, selectAllButton };
        }

        case actionTypes.IsSelectedAll: {
            const { isSelectedAll } = action.payload;
            return { ...state, isSelectedAll };
        }

        case actionTypes.QuickActionsButton: {
            const { quickActionsButton } = action.payload;
            return { ...state, quickActionsButton };
        }

        case actionTypes.EditMoreInfoButton: {
            const { editMoreInfoButton } = action.payload;
            return { ...state, editMoreInfoButton };
        }

        case actionTypes.IsOpenEditMoreInfoButton: {
            const { isOpenEditMoreInfoButton } = action.payload;
            return { ...state, isOpenEditMoreInfoButton };
        }

        case actionTypes.StatusEditMoreInfoButton: {
            const { statusEditMoreInfoButton } = action.payload;
            return { ...state, statusEditMoreInfoButton };
        }

        case actionTypes.DuplicateButton: {
            const { duplicateButton } = action.payload;
            return { ...state, duplicateButton };
        }

        case actionTypes.StatusDuplicateButton: {
            const { statusDuplicateButton } = action.payload;
            return { ...state, statusDuplicateButton };
        }

        case actionTypes.StatusCloneModal: {
            const { statusCloneModal } = action.payload;
            return { ...state, statusCloneModal };
        }
        case actionTypes.StatusDeleteModal: {
            const { statusDeleteModal } = action.payload;
            return { ...state, statusDeleteModal };
        }

        case actionTypes.StatusLaunchModal: {
            const { statusLaunchModal } = action.payload;
            return { ...state, statusLaunchModal };
        }

        case actionTypes.SelectedLength: {
            const { selectedLength } = action.payload;
            return { ...state, selectedLength };
        }

        case actionTypes.SearchFilter: {
            const { searchFilter } = action.payload;
            return { ...state, searchFilter };
        }

        case actionTypes.ShowSearchFilter: {
            const { showSearchFilter } = action.payload;
            return { ...state, showSearchFilter };
        }

        case actionTypes.PreCampaignList: {
            const { preCampaignList } = action.payload;
            return { ...state, preCampaignList };
        }

        case actionTypes.PreCampaignListCountTotal: {
            const { preCampaignListCountTotal } = action.payload;
            return { ...state, preCampaignListCountTotal };
        }

        case actionTypes.PreCampaignListPageNumber: {
            const { preCampaignListPageNumber } = action.payload;
            return { ...state, preCampaignListPageNumber };
        }

        case actionTypes.PreCampaignListSelectedList: {
            const { preCampaignListSelectedList } = action.payload;
            return { ...state, preCampaignListSelectedList };
        }

        case actionTypes.EditInTable: {
            const { editInTable } = action.payload;
            return { ...state, editInTable };
        }

        case actionTypes.ToggleCourseEditButton: {
            const { isOpenCourseEditButton } = action.payload;
            return { ...state, isOpenCourseEditButton };
        }

        case actionTypes.IsCourseEditButtonExist: {
            const { isCourseEditButton } = action.payload;
            return { ...state, isCourseEditButton };
        }

        case actionTypes.SetSubheader: {
            const { subheader, path } = action.payload;
            return { ...state, subheader, path };
        }
        case actionTypes.SetLanguage: {
            const { language, path } = action.payload;
            return { ...state, language, path };
        }
        case actionTypes.SetCourseSlug: {
            const { courseSlug } = action.payload;
            return { ...state, courseSlug };
        }
        case actionTypes.SetSelectedCampaign: {
            const { selectedCampaign } = action.payload;
            return { ...state, selectedCampaign };
        }
        case actionTypes.SetCourseStatus: {
            const { courseStatus } = action.payload;
            return { ...state, courseStatus };
        }
        case actionTypes.SetCourseDetailLoading: {
            const { isCourseDetailLoading } = action.payload;
            return { ...state, isCourseDetailLoading };
        }

        default:
            return state;
    }
};

export const actions = {
    toggleAddPreCampaignButton: isPreCampaignButton => ({
        type: actionTypes.ToggleAddPreCampaignButton,
        payload: { isPreCampaignButton }
    }),

    toggleAddCoursesButton: isCoursesButton => ({
        type: actionTypes.ToggleAddCoursesButton,
        payload: { isCoursesButton }
    }),
    gridOrTableButton: gridOrTable => ({
        type: actionTypes.GridOrTableButton,
        payload: { gridOrTable }
    }),

    setGridOrTableFilter_precampaign: gridOrTableFilter => ({
        type: actionTypes.GridOrTableFilter,
        payload: { gridOrTableFilter }
    }),

    searchPrecampaign: searchInput_precampaign => ({
        type: actionTypes.Search,
        payload: { searchInput_precampaign }
    }),

    setSelectAllButton: selectAllButton => ({
        type: actionTypes.SelectAllButton,
        payload: { selectAllButton }
    }),

    toggleSelectedAll: isSelectedAll => ({
        type: actionTypes.IsSelectedAll,
        payload: { isSelectedAll }
    }),

    setQuickActionsButton: quickActionsButton => ({
        type: actionTypes.QuickActionsButton,
        payload: { quickActionsButton }
    }),

    setEditMoreInfoButton: editMoreInfoButton => ({
        type: actionTypes.EditMoreInfoButton,
        payload: { editMoreInfoButton }
    }),

    toggleEditMoreInfoButton: isOpenEditMoreInfoButton => ({
        type: actionTypes.IsOpenEditMoreInfoButton,
        payload: { isOpenEditMoreInfoButton }
    }),

    setStatusEditMoreInfoButton: statusEditMoreInfoButton => ({
        type: actionTypes.StatusEditMoreInfoButton,
        payload: { statusEditMoreInfoButton }
    }),

    setDuplicateButton: duplicateButton => ({
        type: actionTypes.DuplicateButton,
        payload: { duplicateButton }
    }),

    setStatusDuplicateButton: statusDuplicateButton => ({
        type: actionTypes.StatusDuplicateButton,
        payload: { statusDuplicateButton }
    }),

    setStatusCloneModal: statusCloneModal => ({
        type: actionTypes.StatusCloneModal,
        payload: { statusCloneModal }
    }),

    setStatusDeleteModal: statusDeleteModal => ({
        type: actionTypes.StatusDeleteModal,
        payload: { statusDeleteModal }
    }),

    setStatusLaunchModal: statusLaunchModal => ({
        type: actionTypes.StatusLaunchModal,
        payload: { statusLaunchModal }
    }),

    setSelectedLength: selectedLength => ({
        type: actionTypes.SelectedLength,
        payload: { selectedLength }
    }),

    setSearchFilter: searchFilter => ({
        type: actionTypes.SearchFilter,
        payload: { searchFilter }
    }),

    setShowSearchFilter: showSearchFilter => ({
        type: actionTypes.ShowSearchFilter,
        payload: { showSearchFilter }
    }),

    setPreCampaignList: preCampaignList => ({
        type: actionTypes.PreCampaignList,
        payload: { preCampaignList }
    }),

    setPreCampaignListCountTotal: preCampaignListCountTotal => ({
        type: actionTypes.PreCampaignListCountTotal,
        payload: { preCampaignListCountTotal }
    }),

    setPreCampaignListPageNumber: preCampaignListPageNumber => ({
        type: actionTypes.PreCampaignListPageNumber,
        payload: { preCampaignListPageNumber }
    }),

    setPreCampaignListSelectedList: preCampaignListSelectedList => ({
        type: actionTypes.PreCampaignListSelectedList,
        payload: { preCampaignListSelectedList }
    }),

    setEditInTable: editInTable => ({
        type: actionTypes.EditInTable,
        payload: { editInTable }
    }),

    isCourseEditButtonExist: isCourseEditButton => ({
        type: actionTypes.IsCourseEditButtonExist,
        payload: { isCourseEditButton }
    }),

    toggleCourseEditButton: isOpenCourseEditButton => ({
        type: actionTypes.ToggleCourseEditButton,
        payload: { isOpenCourseEditButton }
    }),

    setSubheader: (subheader, path) => ({
        type: actionTypes.SetSubheader,
        payload: { subheader, path }
    }),

    setLanguage: (language, path) => ({
        type: actionTypes.SetLanguage,
        payload: { language, path }
    }),
    setCourseSlug: (courseSlug,) => ({
        type: actionTypes.SetCourseSlug,
        payload: { courseSlug }
    }),
    setSelectedCampaign: (selectedCampaign,) => ({
        type: actionTypes.SetSelectedCampaign,
        payload: { selectedCampaign }
    }),
    setCourseStatus: (courseStatus) => ({
        type: actionTypes.SetCourseStatus,
        payload: { courseStatus }
    }),
    setCourseDetailLoading: (isCourseDetailLoading) => ({
        type: actionTypes.SetCourseDetailLoading,
        payload: { isCourseDetailLoading }
    }),
};
