import React from "react";
import Select from "react-select";

import { connect } from "react-redux";
import * as app from "../../../app/redux/appRedux";
import * as accounts from "../../accounts/redux/accountsRedux";
import * as campaigns from "../../campaigns/redux/campaignsRedux";
import { post } from "../../../networking/RequestService";
// import { DebounceInput } from "react-debounce-input";
import SearchInput from "./../../../app/partials/SearchInput";

class SearchFilter extends React.Component {
    state = {
        search: ""
    };

    onChange = selectedOption => {
        this.props.selectType(selectedOption);
        /*if(this.state.department !== selectedOption){
            this.props.saveCompanyUsers([]);
            this.props.selectEmployee({ value: 0, label: "All Employees" })
        }
        this.props.selectDepartment(selectedOption);
        this.setState({ department: selectedOption })*/
    };

    render() {
        console.log("this.props: ", this.props);
        if (this.props.module === "account") var inputValue = this.props.searchInput_accounts;
        else if (this.props.module === "precampaign") var inputValue = this.props.searchInput_precampaign;
        else if (this.props.module === "return") var inputValue = this.state.inputValue;
        return (
            <SearchInput
                minLength={1}
                debounceTimeout={300}
                onChange={event => {
                    if (this.props.module === "account") this.props.search(event.target.value);
                    else if (this.props.module === "precampaign") this.props.searchPrecampaign(event.target.value);
                    else if (this.props.module === "return") {
                        this.props.searchReturn(event.target.value);
                        this.setState({ inputValue: event.target.value });
                    }
                }}
                className="form-control"
                variant={this.props.variant}
                placeholder={this.props.placeholder ? this.props.placeholder : "Search..."}
                id="kt_datatable_search_query"
                value={inputValue}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        searchInput_accounts: state.accounts.searchInput,
        searchInput_precampaign: state.campaigns.searchInput_precampaign
    };
};

const mapDispatchToProps = {
    ...accounts.actions,
    ...campaigns.actions
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
