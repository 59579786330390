/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import DropdownPopleads_precampaign from "../../../../_partials/dropdowns/DropdownPopleads_precampaign";
import { toAbsoluteUrl } from "../../../../_helpers";
import { connect } from "react-redux";
import * as campaignsRedux from "../../../../../app/popleads/modules/campaigns/redux/campaignsRedux"

const QuickActionsDropdownToggle = forwardRef((props, ref) => {
  return (
    <a
      ref={ref}
      href="#"
      onClick={e => {
        e.preventDefault();
        props.onClick(e);
      }}
      id="kt_subheader_quick_actions"
      className="btn btn-sm btn-clean btn-icon"
    >
      {" "}
      <span className="svg-icon svg-icon-success svg-icon-lg">
        <span className="svg-icon-success svg-icon-2x">
          <SVG title='Quick actions' src={toAbsoluteUrl("/media/svg/icons/Files/File-plus.svg")} />
        </span>
      </span>
    </a>
  );
});

function QuickActions(props) {
  return (
    <>
      {props.quickActionsButton && props.user && props.user.permissions && props.selectedLength > 0 && (props.user.permissions.LAUNCH_CLONE_PRECAMPAIGNS === 1  || props.user.permissions.DELETE_PRECAMPAIGN === 1) && 
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="quick-actions-tooltip">Quick actions</Tooltip>}
        >
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              as={QuickActionsDropdownToggle}
              id="dropdown-toggle-quick-actions-subheader"
            />

            <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right dropdownDropdownPreCampaigns">
              
                <DropdownPopleads_precampaign />
              
            </Dropdown.Menu>
          </Dropdown>
        </OverlayTrigger>
      }
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    quickActionsButton: state.campaigns.quickActionsButton,
    user: state.auth.user,
    selectedLength: state.campaigns.selectedLength,
  };
};

const mapDispatchToProps = {
  ...campaignsRedux.actions
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickActions);
