import React from 'react';
import AsyncSelect from 'react-select/async';
import SelectInput from "../components/inputs/selectInput"
import { connect } from "react-redux";
import * as app from "../../../app/redux/appRedux";
import * as accounts from "../../accounts/redux/accountsRedux";
import { post } from "../../../networking/RequestService";
class IndustryFilter extends React.Component {

    state = {
        industry: "",
        input: "",
        options: [
            { value: 0, label: "All Industries" },
        ]
    }

    onChange = selectedOption => {
        console.log("selectedOption: ", selectedOption)
        this.props.selectIndustry(selectedOption)
        console.log("this.props.selectedIndustry: ", this.props.selectedIndustry)
    };

    /*loadOptions = (inputValue) => {
        console.log("inputValue: ",inputValue)
        const companyUsers = {
            fn: "getListFromDataSource",
            search_string: inputValue,
            field_name: 'sector'
        }

        post(companyUsers).then(({ data: { result_list } }) => {
            let tempOptions = [{ value: 0, label: "All Industries" }]
            result_list.forEach(function (e) {
                const option = { value: e.sector_name, label: e.sector_name }
                tempOptions.push(option)
            })
            this.setState({ options: tempOptions });
        });
    };*/

    /*componentDidMount() {
        this.loadOptions("");
    }*/

    render() {
        console.log("this.state.options: ", this.state.options)
        return (
            /*<AsyncSelect
                className="subHeaderForm"
                classNamePrefix="subHeaderFilter"
                defaultOptions={this.state.options}
                options={this.state.options}
                defaultValue={this.props.selectedIndustry}
                loadOptions={this.loadOptions}
                value={this.props.selectedIndustry}
                onChange={this.onChange}
            />*/

            <SelectInput
                /*object={{
                    data: {
                        options: this.state.options,
                    },
                }}*/
                object={{
                    fn: "getListFromDataSource",
                    filterName: 'industryFilter',
                    field_name: 'sector',
                    name: 'Industry',
                }}

                onChange={(selected) => this.onChange(selected)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedIndustry: state.accounts.selectedIndustry
    };
};

const mapDispatchToProps = {
    ...accounts.actions,
    ...app.actions,
};


export default connect(mapStateToProps, mapDispatchToProps)(IndustryFilter);