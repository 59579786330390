import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            variant: this.props.variant, // primary, secondary, success, danger, warning, disabledbtn
            outline: this.props.outline,
            icon: this.props.icon,
            disabled: this.props.disabled,
            src: this.props.src,
            className: this.props.className
        };
    }

    render() {
        let className = "btn btn-";
        let link = toAbsoluteUrl(`${this.state.src}`);
        if (this.state.outline) className += "outline-";
        className += this.state.variant;
        if (this.state.icon === true) className += " p-0";
        if (this.state.className) {
            className = className + " " + this.state.className;
        }
        return (
            <>
                {this.props.title && this.props.title !== "" && (
                    <>
                        {this.state.icon ? (
                            <button
                                style={this.props.style}
                                onClick={this.props.onClick}
                                className={className + " icon-button-padding"}
                                disabled={this.props.disabled}>
                                <div className="icon-button d-flex justify-content-between">
                                    <div className="icon-button2">
                                        <SVG src={link}></SVG>
                                    </div>
                                    <span className={(this.props.variant === "borderless") ? "icon-button-span d-flex align-items-center justify-content-center w-100" : "icon-button-span d-flex align-items-center justify-content-center w-100 px-4"}>
                                        {this.props.title}
                                    </span>
                                </div>
                            </button>
                        ) : (
                            <button style={this.props.style} onClick={this.props.onClick} className={className} disabled={this.props.disabled}>
                                {this.props.title}
                            </button>
                        )}
                    </>
                )}

                {(!this.props.title || this.props.title === "") && this.props.icon && (
                    <button style={this.props.style} onClick={this.props.onClick} className={className + " icon-padding"}>
                        <div className="icon-button3">
                            <SVG src={link}></SVG>
                        </div>
                    </button>
                )}
            </>
        );
    }
}

Button.propTypes = {
    title: PropTypes.node,
    variant: PropTypes.string,
    outline: PropTypes.bool,
    icon: PropTypes.bool,
    disabled: PropTypes.bool,
    src: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Button);

{
    /* <Button 
                             title="popleads"
                            variant="danger"
                            outline={false}
                            icon={false}
                            disabled={false}
                            src="delete"
                            />
                             <Button 
                             title="popleads"
                            variant="warning"
                            outline={true}
                            icon={true}
                            disabled={false}
                            src={toAbsoluteUrl("/media/svg/popleads/warning.svg")}
                            /> */
}
