import React from 'react';
import Select from 'react-select';
import { connect } from "react-redux";
import * as reportsRedux from "../../reports/redux/reportsRedux";
import SelectInput from "../components/inputs/selectInput"

class CampaignTypeFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            campaignTypeList: "",
            inputValue: "",
            options: [
                { value: "Coaching", label: "Coaching" },
                { value: "In-house", label: "In-house" },
                { value: "LVT In-House", label: "LVT In-House" },
                { value: "LVT Public", label: "LVT Public" },
                { value: "Mentoring", label: "Mentoring" },
                { value: "Online", label: "Online" },
                { value: "Public", label: "Public" },
            ],
        }
    }

    onChange = selectedOption => {
        this.props.selectCampaignTypeList(selectedOption);
        this.setState({ campaignTypeList: selectedOption })
    };

    onInputChange = (str, { action }) => {
        if (action === "set-value") return false;
        console.log("str ", str)
        this.setState({ inputValue: str });
    };

    render() {
        return (
            <>
                {this.props.isMultiSelect ?
                    <SelectInput
                        object={{
                            name: "Campaign Type",
                            data: {
                                options: this.state.options,
                            },
                            multi: true,
                            allSelectable: true
                        }}
                        onChange={this.onChange}
                        onInputChange={this.onInputChange}
                        infinite={false}

                    />
                    :
                    <Select
                        className="selectInputNewFilter_single"
                        classNamePrefix="selectInputNewFilter"
                        options={this.state.options}
                        defaultValue={this.props.campaignTypeList}
                        value={this.props.campaignTypeList}
                        onChange={this.onChange}
                        closeMenuOnSelect={true}
                        isMulti={this.props.isMultiSelect}
                    />
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        campaignTypeFilter: state.reports.selectedCampaignTypeFilter,
    };
};

const mapDispatchToProps = {
    ...reportsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignTypeFilter);
