import React from 'react';
import Select from 'react-select';
import SelectInput from "../components/inputs/selectInput"
import { connect } from "react-redux";
import * as app from "../../../app/redux/appRedux";
import * as accounts from "../../accounts/redux/accountsRedux";
class TypeFilter extends React.Component {

    state = {
        type: "",
        options: [
            { value: "All Types", label: "All Types" },
            { value: "Master Key", label: "Master Key" },
            { value: "Opened Master Key", label: "Opened Master Key" },
            { value: "No Call", label: "No Call" },
            { value: "Marketing Inquiries", label: "Marketing Inquiries" },
            { value: "Other", label: "Other" }
        ]
    }

    onChange = selectedOption => {
        this.props.selectType(selectedOption)
        /*if(this.state.department !== selectedOption){
            this.props.saveCompanyUsers([]);
            this.props.selectEmployee({ value: 0, label: "All Employees" })
        }
        this.props.selectDepartment(selectedOption);
        this.setState({ department: selectedOption })*/
    };

    componentDidMount() {
        if (!this.props.selectedType) {
            this.props.selectType(this.state.options[0]);
        }
    }

    render() {

        return (
            /*<Select
                className="subHeaderForm"
                classNamePrefix="subHeaderFilter"
                options={this.state.options}
                defaultValue={this.props.selectedType}
                value={this.props.selectedType}
                onChange={this.onChange}
            />
            */
            <SelectInput
                object={{
                    data: {
                        options: this.state.options,
                    },
                    name: "Type",
                }}
                onChange={(selected) => this.onChange(selected)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedType: state.accounts.selectedType
    };
};

const mapDispatchToProps = {
    ...accounts.actions,
    ...app.actions,
};


export default connect(mapStateToProps, mapDispatchToProps)(TypeFilter);