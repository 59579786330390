import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import * as appRedux from "../../../../../../popleads/app/redux/appRedux";

import PDFCommonStyles from "../../../pdf-common.module.scss";

import ColumnChart from "./ColumnChart";

import styles from "./Page3.module.scss";
import AnswerList from "./AnswerList";

// var result = inputArray.reduce((resultArray, item, index) => {
//     const chunkIndex = Math.floor(index/perChunk)

//     if(!resultArray[chunkIndex]) {
//       resultArray[chunkIndex] = [] // start a new chunk
//     }

//     resultArray[chunkIndex].push(item)

//     return resultArray
//   }, [])

class Page3 extends React.Component {
    render() {
        return (
            <>
                {this.props.answerList
                    // Bu kısım biraz kafa karıştırıcı olabilir.
                    // reduce array'i iki boyutlu chunk'a dönüştürüyor.

                    .reduce((resultArray, item, index) => {
                        const chunkIndex = Math.floor(index / 2);

                        if (!resultArray[chunkIndex]) {
                            resultArray[chunkIndex] = [];
                        }

                        resultArray[chunkIndex].push(item);

                        return resultArray;
                    }, [])
                    .map((answers, i) => (
                        <React.Fragment key={i}>
                            <div className={PDFCommonStyles.page}>
                                <div className={`d-flex ${PDFCommonStyles.headerBar}`}>
                                    <span>Evaluation Form Report</span>
                                    <span className="ml-8 font-weight-bold">
                                        {this.props.surveyCampaign?.campaignName}
                                    </span>
                                </div>

                                {answers.map((answer, j) => (
                                    <React.Fragment key={j}>
                                        {answer.textAnswerList ? (
                                            <div className={PDFCommonStyles.content}>
                                                <div className={PDFCommonStyles.question}>
                                                    <span>Q{i * 2 + j + 1}</span> {answer.question}
                                                </div>
                                                <div className="d-flex">
                                                    <span className={"mr-6"}>
                                                        Total Answered: {answer.totalAnswered}
                                                    </span>{" "}
                                                    <span className={"mr-6"}>Skipped: {answer.skipped}</span>
                                                </div>
                                                <AnswerList answers={answer.textAnswerList} />
                                            </div>
                                        ) : (
                                            <div className={PDFCommonStyles.content}>
                                                <div className={PDFCommonStyles.question}>
                                                    <span>Q{i * 2 + j + 1}</span> {answer.question}
                                                </div>
                                                <div className="d-flex">
                                                    <span className={"mr-6"}>
                                                        Total Answered: {answer.totalAnswered}
                                                    </span>{" "}
                                                    <span className={"mr-6"}>Skipped: {answer.skipped}</span>{" "}
                                                    <span className={"mr-6"}>
                                                        Weighted Average: {answer.averageScore}
                                                    </span>
                                                </div>
                                                <ColumnChart answer={answer} fields={this.props.fields} />
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        </React.Fragment>
                    ))}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    ...appRedux.actions
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Page3));
