import React from "react";
/*import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';*/
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';

import { connect } from "react-redux";
// import * as reportsRedux from "../../reports/redux/reportsRedux";
import * as reportsRedux from "../../reports/redux/reportsRedux";

import * as util from "../../../app/functions/util";
import * as app from "../../../app/redux/appRedux";

class DateFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: [{
        startDate: moment().startOf('year').toDate(),
        endDate: moment().endOf('year').toDate(),
        key: 'selection',
      }],
      dateSurvey: [{
        startDate: null,
        endDate: new Date(),
        key: 'selection',
      }],
      staticRanges: [
        ...createStaticRanges([
          {
            label: 'All Time',
            range: () => ({
              startDate: null,
              endDate: new Date(),
            })
          },
          {
            label: 'Last Day',
            range: () => ({
              startDate: moment().subtract(1, 'days').toDate(),
              endDate: new Date(),
            })
          },
          {
            label: 'Last Week',
            range: () => ({
              startDate: moment().subtract(1, 'weeks').toDate(),
              endDate: new Date(),
            })
          },
          {
            label: 'Last Month',
            range: () => ({
              startDate: moment().subtract(1, 'month').toDate(),
              endDate: new Date(),
            })
          },
          {
            label: 'Last Year',
            range: () => ({
              startDate: moment().subtract(1, 'year').toDate(),
              endDate: new Date(),
            })
          },
          {
            label: 'Today',
            range: () => ({
              startDate: new Date(),
              endDate: new Date(),
            })
          },
          {
            label: 'This Week',
            range: () => ({
              startDate: moment().startOf('week').toDate(),
              endDate: moment().endOf('week').toDate(),
            })
          },
          {
            label: 'This Month',
            range: () => ({
              startDate: moment().startOf('month').toDate(),
              endDate: moment().endOf('month').toDate(),
            })
          },
          {
            label: 'This Year',
            range: () => ({
              startDate: moment().startOf('year').toDate(),
              endDate: moment().endOf('year').toDate(),
            })
          },
        ])
      ]
    }

    if (!this.props.dateFilterSurvey && this.props.date.startDate) {
      console.log("tarih var!", this.props.date)
      if (this.props.date.predefinedTag === "allTime") {
        this.state.date = [{
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }]
      }
      else {
        this.state.date = [{
          startDate: this.props.date.startDate,
          endDate: this.props.date.endDate,
          key: 'selection'
        }]
      }
    }

    if (this.props.dateFilterSurvey && this.props.dateSurvey.startDate) {
      console.log("survey var", this.props.dateSurvey)
      if (this.props.dateSurvey.predefinedTag === "allTime") {
        this.state.dateSurvey = [{
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }]
      }
      else {
        this.state.dateSurvey = [{
          startDate: this.props.date.startDate,
          endDate: this.props.date.endDate,
          key: 'selection'
        }]
      }
    }

    /*this.handleCallback = this.handleCallback.bind(this);
    this.handleEvent = this.handleEvent.bind(this);

    this.handleSelect = this.handleSelect.bind(this);
    */
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (!this.props.dateFilterSurvey && this.props.date) {

        this.setState({
          date: [{
            startDate: this.props.date.startDate,
            endDate: this.props.date.endDate,
            key: 'selection'
          }]
        })
      } else if (this.props.dateSurvey) {
        this.setState({
          dateSurvey: [{
            startDate: this.props.dateSurvey.startDate,
            endDate: this.props.dateSurvey.endDate,
            key: 'selection'
          }]
        })

      }
    }


    console.log("this.date: ", this.props.date)
  }

  selectDate() {
    if (!this.props.date.startDate) {
      this.props.selectDate(this.state.date)
    }
    else {
      //console.log("tarih var!", this.props.date)
      this.setState({
        date: [{
          startDate: moment(this.props.date.startDate, "DD/MM/YYYY").format("MM/DD/YYYY"),
          endDate: moment(this.props.date.endDate, "DD/MM/YYYY").format("MM/DD/YYYY"),
          predefinedTag: this.props.date.predefinedTag,
          dateString: this.props.date.dateString
        }]
      })

    }
  }
  selectDateSurvey() {
    if (!this.props.dateSurvey.startDate) {
      this.props.selectDateSurvey(this.state.dateSurvey)
    }
    else {
      //console.log("tarih var!", this.props.date)
      this.setState({
        date: [{
          startDate: moment(this.props.date.startDate, "DD/MM/YYYY").format("MM/DD/YYYY"),
          endDate: moment(this.props.date.endDate, "DD/MM/YYYY").format("MM/DD/YYYY"),
          predefinedTag: this.props.date.predefinedTag,
          dateString: this.props.date.dateString
        }]
      })
    }
  }

  render() {

    return (
      <span id='dateFilterContainer'>
        <DateRangePicker
          ranges={this.props.dateFilterSurvey ? this.state.dateSurvey : this.state.date}
          staticRanges={this.state.staticRanges}
          inputRanges={[]}
          direction="vertical"
          onChange={item => {
            const date = util.getDateObject(item.selection)
            if (this.props.dateFilterSurvey) {
              this.setState({ dateSurvey: [date] })
            } else if (!this.props.dateFilter) {
              this.setState({ date: [date] })
            }


            if (item.selection.predefinedTag === "thisYear" || item.selection.predefinedTag === "thisMonth") {
              this.props.SetKeyOfTopEmployee(item.selection.predefinedTag)
            }
            if (item.selection.startDate !== item.selection.endDate) {
              console.log("item: ", item)
              if (this.props.dateFilterSurvey) {
                this.props.selectDateSurvey(date)
              } else if (!this.props.dateFilter) {
                this.props.selectDate(date)

              }
            }
          }
          }
        />
      </span>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    date: state.reports.selectedDate,
    dateSurvey: state.reports.selectedDateSurvey,
    keyOfTopEmployee: state.reports.keyOfTopEmployee,
    dateFilterSurvey: state.app.dateFilterSurvey,
  };
};

const mapDispatchToProps = {
  ...app.actions,
  ...reportsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
