import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import * as appRedux from "../../../../../../popleads/app/redux/appRedux";

import PDFCommonStyles from "../../../pdf-common.module.scss";
import styles from "./Page2.module.scss";
import { getUrlQueryParam } from "../../../../../app/functions/util";
import { post } from "../../../../../networking/RequestService";

class Page2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isEmpty: false,
            isError: false,

            campaignId: getUrlQueryParam("campaignId"),
            surveyId: getUrlQueryParam("surveyId"),
            profileId: getUrlQueryParam("profileId"),

            delegateList: []
        };

        this.getData = this.getData.bind(this);
        this.retry = this.retry.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {}

    retry() {
        this.getData();
    }

    getData() {
        this.setState({ isLoading: true });

        const requestObj = {
            fn: "getCampaignDelegateList",
            campaignId: this.state.campaignId,
            surveyId: this.state.surveyId,
            profileId: this.state.profileId
        };
        post(requestObj)
            .then(({ data: { delegateList } }) => {
                this.setState({
                    delegateList,
                    isLoading: false,
                    isEmpty: false,
                    isError: false
                });
            })
            .catch(err => {
                this.setState({
                    isError: true
                });
            });
    }

    render() {
        return (
            <>
                <div className={PDFCommonStyles.page}>
                    <div className={`d-flex ${PDFCommonStyles.headerBar}`}>
                        <span>Evaluation Form Report</span>
                        <span className="ml-8 font-weight-bold">{this.props.surveyCampaign?.campaignName}</span>
                    </div>
                    <h2 className={PDFCommonStyles.subheader}>Delegates</h2>
                    <div className={PDFCommonStyles.content}>
                        <table className={PDFCommonStyles.table}>
                            <thead>
                                {this.state.delegateList.map((delegate, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className={`font-weight-bold ${PDFCommonStyles.emphasize}`}>
                                                {delegate.name}
                                            </div>
                                            <div>{delegate.companyName}</div>
                                        </td>
                                        <td className="font-italic">{delegate.title}</td>
                                        <td>
                                            <a href={`tel:${delegate.phoneNumber}`}>{delegate.phoneNumber}</a>
                                        </td>
                                        <td>
                                            <a href={`mailto:${delegate.email}`}>{delegate.email}</a>
                                        </td>
                                    </tr>
                                ))}
                            </thead>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    ...appRedux.actions
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Page2));
