import React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import * as reportsRedux from "../reports/redux/reportsRedux";
import * as appRedux from "../../app/redux/appRedux";
import * as accountsRedux from "../accounts/redux/accountsRedux";
import * as trainingRedux from "../training/redux/trainingRedux";
import * as campaignsRedux from "../campaigns/redux/campaignsRedux";
import * as surveyRedux from "../survey/redux/surveyRedux";
import EmployeeFilter from "../filters/components/EmployeeFilter";
import DepartmentFilter from "../filters/components/DepartmentFilter";
import DateFilter from "../filters/components/DateFilter";
import SearchFilter from "../filters/components/SearchFilter";

import TypeFilter from "../filters/components/TypeFilter"
import IndustryFilter from "../filters/components/IndustryFilter";
import InquiryTypeFilter from "../filters/components/InquiryTypeFilter";
import FranchiseFilter from "../filters/components/FranchiseFilter";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import * as util from "../../app/functions/util";
import { NavLink } from "react-router-dom";
import Button from "../../components/Button";
import moment from "moment";

class FilterDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggle: false,
            isVisible: false,
            employee: null,
            department: null,
            state: null,
            type: null,
            industry: null,
            inquiryType: null,
            franchise: null,
            employeeFilter: this.props.employeeFilter,
            departmentFilter: this.props.departmentFilter,
            dateFilter: this.props.dateFilter,
            gridOrTable: this.props.gridOrTable,
            gridOrTableFilter: this.props.gridOrTableFilter,
            inquiryTypeFilter: this.props.inquiryTypeFilter,
            franchiseFilter: this.props.franchiseFilter,
            isTrainerEditPermission: false,


        };
        const queryEmployee = JSON.parse(util.getUrlQueryParam("employee"));
        const queryDepartment = JSON.parse(util.getUrlQueryParam("department"));
        const queryDate = JSON.parse(util.getUrlQueryParam("date"));
        const queryInquiry = JSON.parse(util.getUrlQueryParam("inquiry"));
        const queryFranchise = JSON.parse(util.getUrlQueryParam("franchise"));

        if (queryEmployee) {
            this.state.employee = queryEmployee;
            this.props.selectEmployee(queryEmployee);
        } else if (this.props.selectedEmployee) {
            this.setLabelForFilter("employee", this.props.selectedEmployee);
        } else {
            this.state.employee = "All Employees";
        }

        if (queryDepartment) {
            for (let i = 0; i < queryDepartment.length; i++) {
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxampxxx", "&");
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxqmaxxx", "?");
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxequxxx", "=");
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxcomxxx", ",");
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxdotxxx", ".");
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxdquxxx", '"');
                queryDepartment[i].label = queryDepartment[i].label.replaceAll("xxxsquxxx", "'");
            }
            this.state.department = queryDepartment;
            this.props.selectDepartment(queryDepartment);
        } else if (this.props.selectedDepartment) {
            this.state.department = this.props.selectedEmployee;
        } else {
            this.state.department = "All Units";
        }

        if (queryDate) {
            this.props.selectDate({
                startDate: moment(queryDate.startDate, "yyyy-MM-DD"),
                endDate: moment(queryDate.endDate, "yyyy-MM-DD"),
                predefinedTag: queryDate.predefinedTag,
                dateString: queryDate.dateString
            });
            this.state.date = queryDate.dateString;
        } else if (!this.props.selectedDate.startDate) {
            this.props.selectDate({
                startDate: moment()
                    .startOf("year")
                    .toDate(),
                endDate: moment()
                    .endOf("year")
                    .toDate(),
                predefinedTag: "thisYear",
                dateString: "This Year"
            });
            this.state.date = "This Year";
        } else if (this.props.selectedDate.dateString) {
            this.state.date = this.props.selectedDate.dateString;
        } else {
            this.state.date = "This Year";
        }

        if (this.props.selectedType) {
            this.state.type = this.props.selectedType;
        } else {
            this.state.type = "All Types";
        }

        if (this.props.selectedIndustry) {
            this.state.industry = this.props.selectedIndustry;
        } else {
            this.state.industry = "All Industries";
        }

        if (queryInquiry) {
            for (let i = 0; i < queryInquiry.length; i++) {
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxampxxx", "&");
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxqmaxxx", "?");
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxequxxx", "=");
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxcomxxx", ",");
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxdotxxx", ".");
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxdquxxx", '"');
                queryInquiry[i].label = queryInquiry[i].label.replaceAll("xxxsquxxx", "'");
            }
            this.state.inquiryType = queryInquiry;
            this.props.selectInquiryType(queryInquiry);
        } else if (this.props.selectedInquiryType) {
            this.setLabelForFilter("inquiryType", this.props.selectedInquiryType);
        } else {
            this.state.inquiryType = "All Types";
        }

        if (queryFranchise) {
            for (let i = 0; i < queryFranchise.length; i++) {
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxampxxx", "&");
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxqmaxxx", "?");
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxequxxx", "=");
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxcomxxx", ",");
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxdotxxx", ".");
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxdquxxx", '"');
                queryFranchise[i].label = queryFranchise[i].label.replaceAll("xxxsquxxx", "'");
            }
            this.state.franchise = queryFranchise;
            this.props.selectFranchise(queryFranchise);
        } else if (this.props.selectedFranchise) {
            this.setLabelForFilter("franchise", this.props.selectedFranchise);
        } else {
            this.state.franchise = "HQ";
        }

    }
    setFilters() {
        this.setState({
            employeeFilter: this.props.employeeFilter,
            departmentFilter: this.props.departmentFilter,
            dateFilter: this.props.dateFilter,
            gridOrTableFilter: this.props.gridOrTableFilter,
            typeFilter: this.props.typeFilter,
            industryFilter: this.props.industryFilter,
            inquiryTypeFilter: this.props.inquiryTypeFilter,
            franchiseFilter: this.props.franchiseFilter,
            searchInput: this.props.searchInput,
            isEditButton: this.props.isEditButton,
            isOpenEditButton: this.props.isOpenEditButton,
            isOpenAccountEditButton: this.props.isOpenAccountEditButton,
            isAccountEditButton: this.props.isAccountEditButton,
            isOpenAccountMergeButton: this.props.isOpenAccountMergeButton,
            isAccountMergeButton: this.props.isAccountMergeButton,
            searchInput_precampaign: this.props.searchInput_precampaign,
            isPreCampaignButton: this.props.isPreCampaignButton,
            editMoreInfoButton: this.props.editMoreInfoButton,
            isOpenEditMoreInfoButton: this.props.isOpenEditMoreInfoButton,
            duplicateButton: this.props.duplicateButton,
            selectedLength_precampaign: this.props.selectedLength,
            showAddSurveyButton: this.props.showAddSurveyButton,
            showEditSurveyButton: this.props.showEditSurveyButton,
            statusEditSurveyButton: this.props.statusEditSurveyButton,
            surveyId: this.props.surveyId
        });
    }
    componentDidMount() {
        this.setFilters();
        this.setTrainerEdit();
        this.filterDateScroll();
    }
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            if (this.props.selectedEmployee) {
                this.setLabelForFilter("employee", this.props.selectedEmployee);
            }

            if (this.props.selectedDepartment) {
                this.setLabelForFilter("department", this.props.selectedDepartment);
            }

            if (this.props.selectedInquiryType) {
                this.setLabelForFilter("inquiryType", this.props.selectedInquiryType);
            }

            if (this.props.selectedFranchise) {
                this.setLabelForFilter("franchise", this.props.selectedFranchise);
            }

            if (this.props.selectedDate) {
                if (this.props.selectDate.dateString !== this.state.date)
                    this.setState({ date: this.props.selectedDate.dateString });
            }

            if (this.props.selectedType) {
                if (this.props.selectedType.label !== this.state.selectedType)
                    this.setState({ type: this.props.selectedType.label });
            }

            if (this.props.selectedIndustry) {
                if (this.props.selectedIndustry.label !== this.state.selectedIndustry)
                    this.setState({
                        industry: this.props.selectedIndustry.label
                    });
            }
            this.setFilters();
        }

        if (prevProps.gridOrTable !== this.props.gridOrTable) {
            this.setState({ gridOrTable: this.props.gridOrTable });
        }

        if (prevProps.user !== this.props.user) {
            this.setTrainerEdit();
        }

    }
    setLabelForFilter(filterName, filterArray) {
        if (filterName == "employee") {
            if (Array.isArray(filterArray)) {
                if (filterArray.length === 0) {
                    this.setState({ employee: "All Employees" });
                }
                if (filterArray.length === 1) {
                    if (filterArray[0].label !== this.state.employee) this.setState({ employee: filterArray[0].label });
                } else if (filterArray.length > 1) {
                    this.setState({
                        employee: filterArray.length + " Employees"
                    });
                }
            } else if (filterArray.label !== this.state.employee) this.setState({ employee: filterArray.label });
        } else if (filterName == "department") {
            if (Array.isArray(filterArray)) {
                if (filterArray.length === 0) {
                    this.setState({ department: "All Units" });
                }
                if (filterArray.length === 1) {
                    if (filterArray[0].label !== this.state.department)
                        this.setState({ department: filterArray[0].label });
                } else if (filterArray.length > 1) {
                    this.setState({
                        department: filterArray.length + " Units"
                    });
                }
            } else if (filterArray.label !== this.state.department) this.setState({ department: filterArray.label });
        } else if (filterName == "inquiryType") {
            if (Array.isArray(filterArray)) {
                if (filterArray.length === 0) {
                    this.setState({ inquiryType: "All Types" });
                }
                if (filterArray.length === 1) {
                    if (filterArray[0].label !== this.state.inquiryType)
                        this.setState({ inquiryType: filterArray[0].label });
                } else if (filterArray.length > 1) {
                    this.setState({
                        inquiryType: filterArray.length + " Types"
                    });
                }
            } else if (filterArray.label !== this.state.inquiryType) this.setState({ inquiryType: filterArray.label });
        } else if (filterName == "franchise") {
            if (Array.isArray(filterArray)) {
                if (filterArray.length === 0) {
                    this.setState({ franchise: "HQ" });
                }
                if (filterArray.length === 1) {
                    if (filterArray[0].label !== this.state.franchise)
                        this.setState({ franchise: filterArray[0].label });
                } else if (filterArray.length > 1) {
                    this.setState({
                        franchise: filterArray.length + " Franchises"
                    });
                }
            } else if (filterArray.label !== this.state.franchise) this.setState({ franchise: filterArray.label });
        }
    }
    setTrainerEdit() {
        if (this.props.user && this.props.user.permissions && this.props.user.permissions.TRAINER_EDIT === 1) {
            this.setState({ isTrainerEditPermission: true });
        }
    }
    filterDateScroll() {
        document.querySelector('.rdrStaticRanges .rdrStaticRangeSelected').scrollIntoView({ behavior: 'smooth', inline: 'end', block: 'end' });
    }
    render() {
        return (
            <>
                {this.state.searchInput === "account" && <SearchFilter module="account" className="searchPL deneme" /> //search input for accounts page
                }
                {this.state.searchInput === "precampaign" && (
                    <div className="mr-3">
                        <SearchFilter module="precampaign" className="searchPL" />
                    </div>
                ) //search input for precampaign page
                }

                <Dropdown align="end" onToggle={() => { this.setState({ isToggle: true, isVisible: true }); }} show={this.state.isToggle}>
                    <Dropdown.Toggle
                        id="kt_filter_toggle"
                        variant="secondary"
                        className="ml-2 ml-sm-4 hide-chevron font-weight-normal p-0">
                        <span className="svg-icon2">
                            <SVG title="Filters" src={toAbsoluteUrl("/media/svg/icons/Text/Filters2.svg")} />
                        </span>
                    </Dropdown.Toggle>
                </Dropdown>
                <div className={`mobile-drawer-wrapper`} style={{ display: this.state.isVisible ? 'flex' : 'none' }} onAnimationEnd={() => { if (this.state.isToggle === false) this.setState({ isVisible: false }) }}>
                    <div className={`wrapper-card p-8 ${this.state.isToggle ? 'visible' : 'hidden'}`} >
                        <form>
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="drawer-header m-0">Filters</h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => { this.setState({ isToggle: false }) }} >
                                    <SVG src={toAbsoluteUrl("/media/svg/popleads/plus.svg")} />
                                </button>
                            </div>
                            <div>
                                <div className="navi navi-hover mt-8 mx-xs-5">
                                    <div className="row">
                                        {this.props.industryFilter && (
                                            <div className="col-md-6 col-sm-12 pl-md-0">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                            <i className="flaticon2-world primary-color-important"></i>
                                                        </div>
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <IndustryFilter />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        )}

                                        {this.props.typeFilter && (
                                            <div className="col-md-6 col-sm-12 pr-md-0">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                            <i className="flaticon2-tag primary-color-important"></i>
                                                        </div>
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <TypeFilter />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        )}

                                        {this.props.employeeFilter && (
                                            <div className="col-md-6 col-sm-12">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <EmployeeFilter isMultiSelect={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        )}
                                        {this.props.departmentFilter && (
                                            <div className="col-md-6 col-sm-12">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <DepartmentFilter isMultiSelect={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        )}

                                        {this.state.inquiryTypeFilter && (
                                            <div className="col-md-6 col-sm-12">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                            <i className="flaticon2-notepad primary-color-important"></i>
                                                        </div>
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <InquiryTypeFilter isMultiSelect={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        )}

                                        {this.state.franchiseFilter && (
                                            <div className="col-md-6 col-sm-12">
                                                <span className="navi-item">
                                                    <div className="navi-link">
                                                        <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                            <i className="flaticon2-notepad primary-color-important"></i>
                                                        </div>
                                                        <div className="navi-text">
                                                            <div className="font-weight-bold">
                                                                <FranchiseFilter isMultiSelect={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        )}
                                    </div>

                                    {this.props.dateFilter && (
                                        <span className="navi-item mt-8">
                                            <div className="navi-link">
                                                <div className="navi-text">
                                                    <div className="font-weight-bold">
                                                        <DateFilter />
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <Button

                                    title={"Close"}
                                    variant="disabledbtn"
                                    outline={false}
                                    onClick={(e) => { e.preventDefault(); this.setState({ isVisible: false }) }}
                                    className="mr-2"
                                />

                            </div>
                        </form>
                    </div>
                </div>




            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        selectedEmployee: state.reports.selectedEmployee,
        selectedDepartment: state.reports.selectedDepartment,
        selectedDate: state.reports.selectedDate,
        employeeFilter: state.app.employeeFilter,
        departmentFilter: state.app.departmentFilter,
        dateFilter: state.app.dateFilter,
        gridOrTable: state.accounts.gridOrTable,
        gridOrTableFilter: state.accounts.gridOrTableFilter,
        selectedType: state.accounts.selectedType,
        selectedIndustry: state.accounts.selectedIndustry,
        selectedInquiryType: state.reports.selectedInquiryType,
        selectedFranchise: state.reports.selectedFranchise,
        typeFilter: state.app.typeFilter,
        industryFilter: state.app.industryFilter,
        inquiryTypeFilter: state.app.inquiryTypeFilter,
        franchiseFilter: state.app.franchiseFilter,
        searchInput: state.app.searchInput,
        isOpenAddTrainerButton: state.training.isOpenAddTrainerButton,
        isOpenCalendarButton: state.training.isOpenCalendarButton,
        isEditButton: state.training.isEditButton,
        isOpenEditButton: state.training.isOpenEditButton,
        user: state.auth.user,
        isAccountEditButton: state.accounts.isAccountEditButton,
        isAccountMergeButton: state.accounts.isAccountMergeButton,
        isOpenAccountEditButton: state.accounts.isOpenAccountEditButton,
        gridOrTable_precampaign: state.campaigns.gridOrTable,
        gridOrTableFilter_precampaign: state.campaigns.gridOrTableFilter,
        searchInput_precampaign: state.campaigns.searchInput_precampaign,
        isPreCampaignButton: state.campaigns.isPreCampaignButton,
        editMoreInfoButton: state.campaigns.editMoreInfoButton,
        isOpenEditMoreInfoButton: state.campaigns.isOpenEditMoreInfoButton,
        duplicateButton: state.campaigns.duplicateButton,
        selectedLength: state.campaigns.selectedLength,
        showAddSurveyButton: state.survey.showAddSurveyButton,
        showEditSurveyButton: state.survey.showEditSurveyButton,
        statusEditSurveyButton: state.survey.statusEditSurveyButton,
        surveyId: state.survey.surveyId
    };
};

const mapDispatchToProps = {
    ...reportsRedux.actions,
    ...appRedux.actions,
    ...accountsRedux.actions,
    ...trainingRedux.actions,
    ...campaignsRedux.actions,
    ...surveyRedux.actions
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterDrawer);
