export const actionTypes = {
  GridOrTable: "[Action] Grid or Table",
  GridOrTableFilter: "[Action] Grid or Table Filter",
  SaveSelectedAccount: "[Action] Save Selected Account",
  SelectType: "[Action] Select Type",
  SelectIndustry: "[Action] Select Industry",
  Search: "[Action] Search",
  IsAccountEditButtonExist: "[Action] Is Edit Button",
  IsAccountMergeButtonExist: "[Action] Is Merge Button",
  ToggleAccountEditButton: "[Action] Toggle Edit Button",
  ToggleAccountMergeButton: "[Action] Toggle Merge Button",
  SetMergeList: "[Action] Set Merge List",
  SetMergePopup: "[Action] Set Merge Popup",
  IsMergedAccount:"[Action] Set Is Merged Account"
};

const initialReportsState = {
  gridOrTable: 'grid',
  gridOrTableFilter: undefined,
  selectedAccount: undefined,
  selectedType: { value: "All Types", label: "All Types" },
  selectedIndustry: { value: 0, label: "All Industries" },
  searchInput: undefined,
  isAccountEditButton: false,
  isAccountMergeButton: false,
  isOpenAccountEditButton: false,
  isOpenAccountMergeButton: false,
  mergeList:[],
  mergePopup:"list",
  isMergedAccount:false
};

export const reducer = (state = initialReportsState, action) => {
  switch (action.type) {

    case actionTypes.GridOrTable: {
      const { gridOrTable } = action.payload;
      return { ...state, gridOrTable }
    }

    case actionTypes.GridOrTableFilter: {
      const { gridOrTableFilter } = action.payload;
      return { ...state, gridOrTableFilter }
    }

    case actionTypes.SaveSelectedAccount: {
      const { selectedAccount } = action.payload;
      return { ...state, selectedAccount }
    }

    case actionTypes.SelectType: {
      const { selectedType } = action.payload;
      return { ...state, selectedType }
    }

    case actionTypes.SelectIndustry: {
      const { selectedIndustry } = action.payload;
      return { ...state, selectedIndustry }
    }

    case actionTypes.Search: {
      const { searchInput } = action.payload;
      return { ...state, searchInput }
    }

    case actionTypes.IsAccountEditButtonExist: {
      const { isAccountEditButton } = action.payload;
      return { ...state, isAccountEditButton }
    }

    case actionTypes.IsAccountMergeButtonExist: {
      const { isAccountMergeButton } = action.payload;
      return { ...state, isAccountMergeButton }
    }

    case actionTypes.ToggleAccountEditButton: {
      const { isOpenAccountEditButton } = action.payload;
      return { ...state, isOpenAccountEditButton }
    }

    case actionTypes.ToggleAccountMergeButton: {
      const { isOpenAccountMergeButton } = action.payload;
      return { ...state, isOpenAccountMergeButton }
    }

    case actionTypes.SetMergeList: {
      const { mergeList } = action.payload;
      return { ...state, mergeList }
    }

    case actionTypes.SetMergePopup: {
      const { mergePopup } = action.payload;
      return { ...state, mergePopup }
    }

    case actionTypes.IsMergedAccount: {
      const { isMergedAccount } = action.payload;
      return { ...state, isMergedAccount }
    }

    default:
      return state;
  }
}

export const actions = {
  setGridOrTable: (gridOrTable) => ({
    type: actionTypes.GridOrTable,
    payload: { gridOrTable }
  }),

  setGridOrTableFilter: (gridOrTableFilter) => ({
    type: actionTypes.GridOrTableFilter,
    payload: { gridOrTableFilter }
  }),

  selectType: (selectedType) => ({
    type: actionTypes.SelectType,
    payload: { selectedType }
  }),

  selectIndustry: (selectedIndustry) => ({
    type: actionTypes.SelectIndustry,
    payload: { selectedIndustry }
  }),

  search: (searchInput) => ({
    type: actionTypes.Search,
    payload: { searchInput }
  }),

  saveSelectedAccount: (selectedAccount) => ({
    type: actionTypes.SaveSelectedAccount,
    payload: { selectedAccount }
  }),

  isAccountEditButtonExist: (isAccountEditButton) => ({
    type: actionTypes.IsAccountEditButtonExist,
    payload: { isAccountEditButton }
  }),

  isAccountMergeButtonExist: (isAccountMergeButton) => ({
    type: actionTypes.IsAccountMergeButtonExist,
    payload: { isAccountMergeButton }
  }),

  toggleAccountEditButton: (isOpenAccountEditButton) => ({
    type: actionTypes.ToggleAccountEditButton,
    payload: { isOpenAccountEditButton }
  }),

  toggleAccountMergeButton: (isOpenAccountMergeButton) => ({
    type: actionTypes.ToggleAccountMergeButton,
    payload: { isOpenAccountMergeButton }
  }),

  setMergeList: (mergeList) => ({
    type: actionTypes.SetMergeList,
    payload: { mergeList }
  }),

  setMergePopup: (mergePopup) => ({
    type: actionTypes.SetMergePopup,
    payload: { mergePopup }
  }),

  setIsMergedAccount: (isMergedAccount) => ({
    type: actionTypes.IsMergedAccount,
    payload: { isMergedAccount }
  }),
};

