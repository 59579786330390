import React from "react";
import { connect } from "react-redux";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import {
    DropdownCustomToggler,
    SubHeaderDropdownButtonToggler
} from "../../../../_metronic/_partials/dropdowns/DropdownCustomToggler.js";
import TextInput from "./components/inputs/textInput";
import NumberInput from "./components/inputs/numberInput";
import SelectInput from "./components/inputs/selectInput";
import DateInput from "./components/inputs/dateInput";
import * as campaignsRedux from "../campaigns/redux/campaignsRedux";
class NewFilter extends React.Component {
    componentDidMount() {
        // console.log("NewFilter.js");
    }

    constructor(props) {
        super(props);

        this.state = {
            filterTitle: "Filter",
            isDropdownOpen: false,
            data: [],
            show: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps && this.props.searchFilter !== prevProps.searchFilter) {
            this.setState({ data: this.props.searchFilter });
        }

        if (prevProps && this.props.showSearchFilter !== prevProps.showSearchFilter) {
            this.setState({ show: this.props.showSearchFilter });
        }
    }

    scrollTo = idx => {
        setTimeout(() => {
            const header = 50;
            const scrollTo = header + idx * 57.5;
            document.getElementById("dropdownNewFilter").scrollTo(0, scrollTo);
        }, 20);
    };

    render() {
        return (
            <>
                {this.state.show && (
                    <Dropdown id="industryDropdown" align="end">
                        <Dropdown.Toggle
                            id="kt_filter_toggle"
                            variant="secondary"
                            className="hide-chevron font-weight-normal p-0">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="filters-tooltip">{this.state.filterTitle}</Tooltip>}>
                                <span className="svg-icon2">
                                    <SVG title="Filters" src={toAbsoluteUrl("/media/svg/icons/Text/Filters2.svg")} />
                                </span>
                            </OverlayTrigger>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl dropdown-menu-withDateRangePicker">
                            <div className="dropdownNewFilter" id="dropdownNewFilter">
                                <form>
                                    {/** Head */}
                                    <div
                                        className="d-flex align-items-center py-5 px-8 bgi-size-cover bgi-no-repeat rounded-top"
                                        style={{
                                            backgroundColor: "#6490BF"
                                        }}>
                                        <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
                                            <span className="svg-icon svg-icon-2x svg-icon-white">
                                                <SVG
                                                    title={this.state.filterTitle}
                                                    src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")}
                                                />
                                            </span>
                                        </span>
                                        <h4 className="text-white m-0 flex-grow-1 mr-3">{this.state.filterTitle}</h4>
                                    </div>
                                    <div className="navi navi-hover my-4 newFilter" id="newFilter">
                                        {this.state &&
                                            this.state.data &&
                                            this.state.data.map((object, i) => (
                                                <div className="row m-0" key={"filter_" + i}>
                                                    <div className="col-sm-12 p-0">
                                                        <span className="navi-item">
                                                            <div className="navi-link">
                                                                <div className="subHeader-dropdownIcon navi-icon mr-2">
                                                                    <SVG src={object.svgUrl} />
                                                                </div>
                                                                <div className="navi-text">
                                                                    <div className="font-weight-bold">
                                                                        {object.type === "text" && (
                                                                            <TextInput
                                                                                onClick={() => this.scrollTo(i)}
                                                                                onInputChange={input => {
                                                                                    object.value = input;
                                                                                    this.setState({
                                                                                        ...this.state.data
                                                                                    });
                                                                                    const search = this.state.data.slice();
                                                                                    this.props.setSearchFilter(search);
                                                                                }}
                                                                                onActionChange={action => {
                                                                                    object.action = action;
                                                                                    this.setState({
                                                                                        ...this.state.data
                                                                                    });
                                                                                    const search = this.state.data.slice();
                                                                                    this.props.setSearchFilter(search);
                                                                                }}
                                                                                object={object}
                                                                            />
                                                                        )}
                                                                        {object.type === "number" && (
                                                                            <NumberInput
                                                                                onClick={() => this.scrollTo(i)}
                                                                                onInputChange={(input, no) => {
                                                                                    if (object.action === "[]") {
                                                                                        if (!no) {
                                                                                            object.valueFrom = input;
                                                                                        } else if (no === 2) {
                                                                                            object.valueTo = input;
                                                                                        }
                                                                                    } else {
                                                                                        object.value = input;
                                                                                    }

                                                                                    this.setState({
                                                                                        ...this.state.data
                                                                                    });
                                                                                    const search = this.state.data.slice();
                                                                                    this.props.setSearchFilter(search);
                                                                                }}
                                                                                onActionChange={action => {
                                                                                    if (
                                                                                        object.action === "[]" ||
                                                                                        action === "[]"
                                                                                    ) {
                                                                                        object.value = null;
                                                                                        object.valueFrom = null;
                                                                                        object.valueTo = null;
                                                                                    }

                                                                                    object.action = action;
                                                                                    this.setState({
                                                                                        ...this.state.data
                                                                                    });
                                                                                    const search = this.state.data.slice();
                                                                                    this.props.setSearchFilter(search);
                                                                                }}
                                                                                object={object}
                                                                            />
                                                                        )}

                                                                        {object.type === "list" && (
                                                                            <SelectInput
                                                                                onClick={() => {
                                                                                    console.log(object)
                                                                                    this.scrollTo(i)
                                                                                }}
                                                                                scrollTo={() => this.scrollTo(i)}
                                                                                onChange={input => {
                                                                                    object.value = input;
                                                                                    this.setState({
                                                                                        ...this.state.data
                                                                                    });
                                                                                    console.log("works")
                                                                                    const search = this.state.data.slice();
                                                                                    this.props.setSearchFilter(search);
                                                                                }}
                                                                                object={object}
                                                                            />
                                                                        )}

                                                                        {object.type === "date" && (
                                                                            <DateInput
                                                                                onClick={() => this.scrollTo(i)}
                                                                                onInputChange={(input, no) => {
                                                                                    if (object.action === "[]") {
                                                                                        if (!no) {
                                                                                            object.valueFrom = input;
                                                                                        } else if (no === 2) {
                                                                                            object.valueTo = input;
                                                                                        }
                                                                                    } else {
                                                                                        object.value = input;
                                                                                    }
                                                                                    this.setState({
                                                                                        ...this.state.data
                                                                                    });
                                                                                    const search = this.state.data.slice();
                                                                                    this.props.setSearchFilter(search);
                                                                                }}
                                                                                onActionChange={action => {
                                                                                    // console.log("obj: ", object);
                                                                                    if (
                                                                                        object.action === "[]" ||
                                                                                        action === "[]"
                                                                                    ) {
                                                                                        object.value = null;
                                                                                        object.valueFrom = null;
                                                                                        object.valueTo = null;
                                                                                    }
                                                                                    object.action = action;
                                                                                    this.setState({
                                                                                        ...this.state.data
                                                                                    });
                                                                                    const search = this.state.data.slice();
                                                                                    this.props.setSearchFilter(search);
                                                                                    // console.log("obj: ", object);
                                                                                }}
                                                                                object={object}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </form>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        searchFilter: state.campaigns.searchFilter,
        showSearchFilter: state.campaigns.showSearchFilter
    };
};

const mapDispatchToProps = {
    ...campaignsRedux.actions
};

export default connect(mapStateToProps, mapDispatchToProps)(NewFilter);
