import React, { createContext, createRef, useCallback, useContext, useLayoutEffect, useMemo, useState } from "react";

/**
 * @typedef TSlidingMenuIndicatorContext
 * @property {() => { ref: React.Ref<any> }} registerMenuItem
 */

/**
 * @type {React.Context<TSlidingMenuIndicatorContext>}
 */
export const SlidingMenuIndicatorContext = createContext({});

/**
 * @type {React.FC}
 */
const SlidingMenuIndicatorProvider = ({ children }) => {
    const [refs, setRefs] = useState([]);
    const [activeRef, setActiveRef] = useState(null);
    const [offset, setOffset] = useState(0);
    const [activeElementOffset, setActiveElementOffset] = useState(0);

    const registerMenuItem = () => {
        const menuItem = createRef();
        setRefs(refs => [
            // Filter out the refs that are removed from the DOM
            ...(refs.filter(ref => ref.current !== null)),
            menuItem
        ]);

        return {
            ref: menuItem
        };
    };

    const observeResize = useCallback(() => {
        setActiveElementOffset(prev => ((activeRef?.current?.offsetTop ? activeRef.current.offsetTop : prev)));
    }, [activeRef]);

    // Add event listener and return a function to remove it
    const _attachEventListener = (element, event, handler) => {
        element.addEventListener(event, handler);
        return () => element.removeEventListener(event, handler);
    };

    useLayoutEffect(() => {
        const handleMouseEnter = ref => e => {
            setOffset(ref.current.offsetTop);
        };

        const handleMouseLeave = () => {
            setOffset(null);
        };

        const handleClick = e => {
            let parentElement = e.target;
            if (e.target.tagName !== "LI") {
                while (parentElement.tagName !== "LI") parentElement = parentElement.parentElement;
            }
            if (!parentElement.classList.contains("menu-item-parent")) {
                setOffset(null);
            }
        };

        const resizeObserver = new ResizeObserver(observeResize);

        const listenerCleaners = [];

        refs.forEach(ref => {
            if (ref.current) {
                listenerCleaners.push(_attachEventListener(ref.current, "mousemove", handleMouseEnter(ref)));
                listenerCleaners.push(_attachEventListener(ref.current, "mouseleave", handleMouseLeave));
                listenerCleaners.push(_attachEventListener(ref.current, "click", handleClick));
                resizeObserver.observe(ref.current);
            }
        });

        return () => {
            refs.forEach(ref => {
                if (ref.current) {
                    resizeObserver.unobserve(ref.current);
                }
            });
            listenerCleaners.forEach(cleaner => cleaner());
            resizeObserver.disconnect();
        };
    }, [refs, activeElementOffset, observeResize]);

    useLayoutEffect(() => {
        const ref = refs.filter(ref => ref.current?.classList?.contains("menu-item-not-hightlighted"))[0];
        if (ref) {
            setActiveRef(ref);
            setActiveElementOffset(ref.current?.offsetTop ? ref.current.offsetTop : 0);
        }
    }, [refs]);

    const memoedValue = useMemo(
        () => ({
            registerMenuItem
        }),
        []
    );

    return (
        <SlidingMenuIndicatorContext.Provider value={memoedValue}>
            <div
                style={{
                    pointerEvents: "none",
                    position: "absolute",
                    // Css variables can be used to avoid hardcoding the height
                    height: "75px",
                    // same goes for the width
                    width: "0.5rem",
                    backgroundColor: "var(--color-success)",
                    // Avoid racing condition with the animation
                    top: offset ? offset : activeElementOffset,
                    left: 0,
                    borderRadius: "999px",
                    transition: "all 0.3s ease",
                    zIndex: "999"
                }}
            />
            {children}
        </SlidingMenuIndicatorContext.Provider>
    );
};

export function useSlidingMenuIndicator() {
    return useContext(SlidingMenuIndicatorContext);
}

export default SlidingMenuIndicatorProvider;
