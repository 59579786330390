import React from 'react';
import Select, { components } from 'react-select';
import { connect } from "react-redux";
import * as app from "../../../app/redux/appRedux";
import * as reportsRedux from "../../reports/redux/reportsRedux";
import { post } from "../../../networking/RequestService";
import * as util from "../../../app/functions/util";
import SelectInput from './inputs/selectInput';
const { ValueContainer, Placeholder } = components;
class DepartmentFilter extends React.Component {

    state = {
        department: "",
        options: [],
        isAllSelected: false,
    }

    constructor(props) {
        super(props);
        this.getDepartments = this.getDepartments.bind(this);
        this.switchCheckbox = this.switchCheckbox.bind(this);
    }

    /*onChange = selectedOption => {
        if (this.state.department !== selectedOption) {
            this.props.saveCompanyUsers([]);
            this.props.selectEmployee([])
        }
        this.props.selectDepartment(selectedOption);
        this.setState({ department: selectedOption })
    };*/

    onChange = selectedOption => {
        console.log("onChange: ", selectedOption)
        this.props.selectDepartment(selectedOption);
        this.props.selectEmployee([]);
        this.setState({ department: selectedOption })
    };

    // onInputChange = (str, { action }) => {
    //     if (action === "set-value") return false;
    //     this.setState({ inputValue: str });
    // };

    saveUsersToState(departments) {
        const options = [];
        //options.push({ value: 0, label: "All Units" });
        if (departments) {
            for (let i = 0; i < departments.length; i++) {
                const dept_units = [];
                for (let j = 0; j < departments[i].unitList.length; j++) {
                    const obj = { label: departments[i].unitList[j].name, value: departments[i].unitList[j].id, selected: false };
                    dept_units.push(obj);
                }

                const obj = { label: departments[i].name, options: dept_units, value: departments[i].id, selected: false };
                options.push(obj);
            }
        }
        this.setState({ options });

        /*if (!this.props.department) {
            this.props.selectDepartment(this.state.options[0]);
        }*/
    }

    componentDidMount() {
        if (this.props.companyDepartments) {
            this.saveUsersToState(this.props.companyDepartments);
        } else {
            const companyDepartments = {
                fn: "getAllUnits"
            }
            post(companyDepartments).then(({ data: { departments } }) => {
                this.saveUsersToState(departments);
                this.props.saveCompanyDepartments(departments);
            });
        }
    }


    getDepartments() {
        return this.props.department;
    }

    switchCheckbox() {
        this.props.setIsSelectAllDepartments(!this.props.isSelectAllDepartments)
        this.setState({ isAllSelected: true })
    }

    render() {
        const isSelectAllDepartments = this.props.isSelectAllDepartments;
        return (
            <>
                {this.props.isMultiSelect ?
                    // <Select
                    //     className="selectInputNewFilterC"
                    //     classNamePrefix="selectInputNewFilter"
                    //     options={this.state.options}
                    //     defaultValue={this.state.department}
                    //     value={this.state.department}
                    //     onChange={this.onChange}
                    //     onInputChange={this.onInputChange}
                    //     isMulti={this.props.isMultiSelect}
                    //     hideSelectedOptions={false}
                    //     backspaceRemovesValue={false}
                    //     blurInputOnSelect={false}
                    //     closeMenuOnSelect={false}
                    //     isClearable={false}
                    //     components={{ Option, MultiValue, Control, Input, ValueContainer: CValueContainer, GroupHeading }}
                    //     selectedDepartment={this.getDepartments()}
                    //     // selectDepartment={(units) => this.selectUnits(units)}
                    //     isSelectAllDepartments={isSelectAllDepartments}
                    //     setIsSelectAllDepartments={this.switchCheckbox}
                    // /> 
                    <SelectInput
                        object={{
                            data: {
                                options: this.state.options,
                            },
                            multi: true,
                            allSelectable: true,
                            name: "Unit",
                        }}
                        onChange={this.onChange}
                        onInputChange={this.onInputChange}
                        isGrouped
                        selectedDepartment={this.props.department}
                        selectDepartment={(units) => {
                            console.alert("departmentfilter units", units)
                            this.selectUnits(units)
                        }
                        }
                        isSelectAllDepartments={isSelectAllDepartments}
                        setIsSelectAllDepartments={this.switchCheckbox}
                        infinite={false}
                    />
                    :
                    <Select
                        className="selectInputNewFilter_single"
                        classNamePrefix="selectInputNewFilter"
                        options={this.state.options}
                        defaultValue={this.state.department}
                        value={this.state.department}
                        onChange={this.onChange}
                        isMulti={this.props.isMultiSelect}
                    />
                }
            </>
        )
    }
}

const groupStyles = {
    // borderBottom: `2px solid #dddddd`,
    color: 'white',
    // padding: '5px 0px',
    display: 'flex',
};


const GroupHeading = function (props) {

    console.log(props)

    function checkGroup() {
        console.log(`checkGroup`)
        console.log("check!")
        let selected = props.selectProps.selectedDepartment;
        for (let i = 0; i < props.selectProps.options.length; i++) {
            const department = props.selectProps.options[i];
            department.selected = true;
            for (let j = 0; j < department.options.length; j++) {
                const unit = department.options[j];
                let found = false;
                if (selected) {
                    for (let k = 0; k < selected.length; k++) {
                        if (selected[k].label === unit.label)
                            found = true;
                    }
                }
                if (!found) department.selected = false;
            }
        }
    }

    function switchChecked(children, options) {
        console.log("switchChecked")
        for (let i = 0; i < options.length; i++) {
            if (children === options[i].label) {
                options[i].selected = !options[i].selected;
                return { status: options[i].selected, index: i };
            }
        }
    };

    function isChecked(children, options) {
        for (let i = 0; i < options.length; i++) {
            if (children === options[i].label) {
                return options[i].selected;
            }
        }
    }

    function selectDepartment(selectedDepartment, selectedOption) {
        console.log(`selectDepartment`)
        let selected = "";
        if (!selectedDepartment) selected = [];
        else selected = selectedDepartment.slice();

        for (let i = 0; i < selectedOption.length; i++) {
            let matched = false;
            for (let j = 0; j < selected.length; j++) {
                if (selectedOption[i].label === selected[j].label) matched = true;
            }
            if (!matched) selected.push(selectedOption[i]);
        }
        return selected;
    }

    function unselectDepartment(selectedDepartment, selectedOption) {
        let selected = [];

        for (let i = 0; i < selectedDepartment.length; i++) {
            let matched = false;
            for (let j = 0; j < selectedOption.length; j++) {
                if (selectedOption[j].label === selectedDepartment[i].label) matched = true;
            }
            if (!matched) selected.push(selectedDepartment[i]);
        }
        return selected;
    }

    function onClickDepartment() {
        console.log("hede!");
        let check = switchChecked(props.children, props.selectProps.options);
        console.log("hede!", props.selectProps.options[check.index].selected);
        let selected;
        props.selectProps.options[check.index].selected = check.status;
        if (check.status) selected = selectDepartment(props.selectProps.selectedDepartment, props.selectProps.options[check.index].options);
        else selected = unselectDepartment(props.selectProps.selectedDepartment, props.selectProps.options[check.index].options);

        props.selectProps.onChange(selected);
        console.log("props: ", props)
    }

    //console.log("props.selectProps: ", props.selectProps);
    checkGroup();
    console.log(props.children)
    console.log(props.selectProps.options.label === props.children)
    console.log("selectedDepartment = ", props.selectProps.selectedDepartment)
    return (
        <div style={groupStyles} className={`selectGroupInput ${!!isChecked(props.children, props.selectProps.options) ? ' groupSelected ' : ""}`} onClick={onClickDepartment}>
            <input
                type="checkbox"
                name='departmentCheck'

                value={props.children}
                checked={!!isChecked(props.children, props.selectProps.options)}

                style={{ marginLeft: "10px" }}
            />
            <components.GroupHeading {...props} />
        </div>
    );
}

const Input = props => {
    return (
        <div>
            <components.Input {...props} />
        </div>
    );
};

const Option = ({ ...props }) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={e => null}
                />{" "}
                <label>{props.label} </label>
            </components.Option>
        </div>
    );
};

const Control = ({ children, ...props }) => {
    //console.log("control_props: ",props)
    return (
        <components.Control  {...props}>
            <input
                type="checkbox"
                name='departmentheck'
                className='selectAllInput'
                value='1'
                checked={!!props.selectProps.isSelectAllDepartments}
                onChange={e => {
                    props.selectProps.setIsSelectAllDepartments()
                    let options = [];
                    if (e.target.checked) {
                        for (let i = 0; i < props.options.length; i++) {
                            for (let j = 0; j < props.options[i].options.length; j++) {
                                options.push(props.options[i].options[j]);
                            }
                        }
                    }
                    props.setValue(options);
                }}
                style={{ marginLeft: "10px" }}
            />{" "}

            {children}
        </components.Control>

    );
};

const MultiValue = ({ children, ...innerProps }) => {
    return (
        <></>
    )
};

const CValueContainer = ({ children, ...props }) => {
    var placeholder = props.selectProps.placeholder
    return (
        <ValueContainer {...props}>
            {props.selectProps.inputValue === "" &&
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
            }
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    )
};

const mapStateToProps = (state) => {
    return {
        department: state.reports.selectedDepartment,
        employee: state.reports.selectedEmployee,
        companyDepartments: state.app.companyDepartments,
        companyUsers: state.app.companyUsers,
        isSelectAllDepartments: state.reports.isSelectAllDepartments
    };
};

const mapDispatchToProps = {
    ...reportsRedux.actions,
    ...app.actions,
};


export default connect(mapStateToProps, mapDispatchToProps)(DepartmentFilter);
