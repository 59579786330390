import moment from 'moment';
import * as constants from "../contants"

export function capitalizeFirstLetterOfWord(str) {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }
    const str2 = arr.join(" ");
    return str2;
}

export function isPropsChanged(prevProps, thisProps) {
    if ((prevProps &&
        isEmployeeChanged(prevProps.employee, thisProps.employee) ||
        isDepartmentChanged(prevProps.department, thisProps.department)) ||
        isCampaignTypeChanged(prevProps.selectedInquiryType, thisProps.selectedInquiryType) ||
        isFranchiseChanged(prevProps.selectedFranchise, thisProps.selectedFranchise) ||
        isCampaignTypeListChanged(prevProps.selectedCampaignTypeList, thisProps.selectedCampaignTypeList) ||
        isTrainerChanged(prevProps.selectedTrainer, thisProps.selectedTrainer) ||
        (prevProps.selectedDate &&
            ((prevProps.selectedDate.firstDate !== thisProps.selectedDate.firstDate) ||
                (prevProps.selectedDate.endDate !== thisProps.selectedDate.endDate) ||
                (prevProps.selectedDate.predefinedTag !== thisProps.selectedDate.predefinedTag))
        ) || (prevProps.selectedDateSurvey &&
            ((prevProps.selectedDateSurvey.firstDate !== thisProps.selectedDateSurvey.firstDate) ||
                (prevProps.selectedDateSurvey.endDate !== thisProps.selectedDateSurvey.endDate) ||
                (prevProps.selectedDateSurvey.predefinedTag !== thisProps.selectedDateSurvey.predefinedTag))
        ) ||
        (prevProps.selectedCountry?.length !== thisProps.selectedCountry?.length) ||
        (prevProps.selectedIndustry?.length !== thisProps.selectedIndustry?.length) ||
        (prevProps.selectedAccount?.length !== thisProps.selectedAccount?.length) ||
        (prevProps.selectedPortfolio?.length !== thisProps.selectedPortfolio?.length)
    ) {
        return true;
    }
    return false;
}

export function isEmployeeChanged(prevEmployee, thisEmployee) {
    if (typeof (prevEmployee) !== typeof (thisEmployee)) {
        return true
    }
    else if (!Array.isArray(prevEmployee)) {
        return prevEmployee && (prevEmployee.value !== thisEmployee.value)
    }
    else {
        if (prevEmployee.length !== thisEmployee.length) return true
        else {
            for (let i = 0; i < prevEmployee.length; i++) {
                if (prevEmployee[i].value !== thisEmployee[i].value) return true
            }
        }
    }
    return false
}
export function isTrainerChanged(prevTrainer, thisTrainer) {
    if (typeof (prevTrainer) !== typeof (thisTrainer)) {
        return true
    }
    else if (!Array.isArray(prevTrainer)) {
        return prevTrainer && (prevTrainer.value !== thisTrainer.value)
    }
    else {
        if (prevTrainer.length !== thisTrainer.length) return true
        else {
            for (let i = 0; i < prevTrainer.length; i++) {
                if (prevTrainer[i].value !== thisTrainer[i].value) return true
            }
        }
    }
    return false
}

export function isDepartmentChanged(prevDepartment, thisDepartment) {
    if (typeof (prevDepartment) !== typeof (thisDepartment)) {
        return true
    }
    else if (!Array.isArray(prevDepartment)) {
        return prevDepartment && (prevDepartment.value !== thisDepartment.value)
    }
    else {
        if (prevDepartment.length !== thisDepartment.length) return true
        else {
            for (let i = 0; i < prevDepartment.length; i++) {
                if (prevDepartment[i].value !== thisDepartment[i].value) return true
            }
        }
    }
    return false
}

export function isCampaignTypeChanged(prevCampaignType, thisCampaignType) {
    if (typeof (prevCampaignType) !== typeof (thisCampaignType)) {
        return true
    }
    else if (!Array.isArray(prevCampaignType)) {
        return prevCampaignType && (prevCampaignType.value !== thisCampaignType.value)
    }
    else {
        if (prevCampaignType.length !== thisCampaignType.length) return true
        else {
            for (let i = 0; i < prevCampaignType.length; i++) {
                if (prevCampaignType[i].value !== thisCampaignType[i].value) return true
            }
        }
    }
    return false
}

export function isCampaignTypeListChanged(prevCampaignTypeList, thisCampaignTypeList) {
    if (typeof (prevCampaignTypeList) !== typeof (thisCampaignTypeList)) {
        return true
    }
    else if (!Array.isArray(prevCampaignTypeList)) {
        return prevCampaignTypeList && (prevCampaignTypeList.value !== thisCampaignTypeList.value)
    }
    else {
        if (prevCampaignTypeList.length !== thisCampaignTypeList.length) return true
        else {
            for (let i = 0; i < prevCampaignTypeList.length; i++) {
                if (prevCampaignTypeList[i].value !== thisCampaignTypeList[i].value) return true
            }
        }
    }
    return false
}

export function isFranchiseChanged(prevFranchise, thisFranchise) {
    if (typeof (prevFranchise) !== typeof (thisFranchise)) {
        return true
    }
    else if (!Array.isArray(prevFranchise)) {
        return prevFranchise && (prevFranchise.value !== thisFranchise.value)
    }
    else {
        if (prevFranchise.length !== thisFranchise.length) return true
        else {
            for (let i = 0; i < prevFranchise.length; i++) {
                if (prevFranchise[i].value !== thisFranchise[i].value) return true
            }
        }
    }
    return false
}

export function getUserIdsFilter(employee) {
    let userIds = []
    if (employee && Array.isArray(employee)) {
        for (let i = 0; i < employee.length; i++) {
            userIds.push(employee[i].value)
        }
    }
    else if (employee && employee.value) {
        userIds = userIds.push(employee.value)
    }
    return userIds
}

export function getUnitIdsFilter(department) {

    let unitIds = []
    if (department && Array.isArray(department)) {
        for (let i = 0; i < department.length; i++) {
            unitIds.push(department[i].value)
        }
    }
    else if (department && department.value) {
        unitIds = unitIds.push(department.value)
    }
    return unitIds
}

export function getUserIdsOrUnitIds(employee, department) {
    let returnObj = {
        userId: null,
        unitId: null
    }

    const userIds = getUserIdsFilter(employee);
    if (userIds.length > 0)
        returnObj.userId = userIds;

    const unitIds = getUnitIdsFilter(department);
    if (unitIds.length > 0)
        returnObj.unitId = unitIds;

    return returnObj
}

export function isChartKeyShow(interval, key) {
    let day = false;
    let month = false;
    let year = false;

    if (!interval) {
        day = true;
        month = true;
        year = true;
    }
    else if (interval === "allTime") {
        month = true;
        year = true;
    }
    else if (interval === "thisYear" || interval === "lastYear") {
        day = true;
        month = true;
    }
    else {
        day = true;
    }

    if (key === "day") {
        return day;
    }
    else if (key === "month") {
        return month;
    }
    else {
        return year;
    }
}

export function getFormatOfNumbers(data) {
    let total = 0;
    let format = '';
    let formatter = '';
    for (let i = 0; i < data.length; i++) {
        total = total + data[i];
    }

    const average = total / data.length;

    if (average > 1000000) {
        format = 'in millions';
        formatter = function (val, index) {
            val = parseInt(val * 100) / 100;
            return val + 'M';
        }
    }
    else if (average > 1000) {
        format = 'in thousands';
        formatter = function (val, index) {
            val = parseInt(val * 100) / 100;
            return val + 'K';
        }
    }
    else {
        format = ''
        formatter = undefined
    }

    return ({
        format: format,
        formatter: formatter
    })
}

export function getDataForFormat(data, format) {
    if (format === 'in millions') {
        for (let i = 0; i < data.length; i++) {
            data[i] = (data[i] / 1000000).toFixed(1);
        }
    }
    else if (format === 'in thousands') {
        for (let i = 0; i < data.length; i++) {
            data[i] = (data[i] / 1000).toFixed(1);
        }
    }
    else {
        for (let i = 0; i < data.length; i++) {
            data[i] = (data[i]).toFixed(1);
        }
    }

    return data
}

export function getDateObject(dateItem) {
    const date = {
        startDate: moment(dateItem.startDate, "DD/MM/YYYY").format("MM/DD/YYYY"),
        endDate: moment(dateItem.endDate, "DD/MM/YYYY").format("MM/DD/YYYY")
    }
    const today = {
        startDate: moment().format("MM/DD/YYYY"),
        endDate: moment().format("MM/DD/YYYY")
    }
    const thisWeek = {
        startDate: moment().startOf('week').format("MM/DD/YYYY"),
        endDate: moment().endOf('week').format("MM/DD/YYYY"),
    }
    const thisMonth = {
        startDate: moment().startOf('month').format("MM/DD/YYYY"),
        endDate: moment().endOf('month').format("MM/DD/YYYY"),
    }
    const thisYear = {
        startDate: moment().startOf('year').format("MM/DD/YYYY"),
        endDate: moment().endOf('year').format("MM/DD/YYYY"),
    }
    const allTime = {
        startDate: "Invalid date",
        endDate: moment(new Date()).format("MM/DD/YYYY"),
    }
    const lastDay = {
        startDate: moment().subtract(1, 'days').format("MM/DD/YYYY"),
        endDate: moment(new Date()).format("MM/DD/YYYY"),
    }
    const lastWeek = {
        startDate: moment().subtract(1, 'weeks').format("MM/DD/YYYY"),
        endDate: moment(new Date()).format("MM/DD/YYYY"),
    }
    const lastMonth = {
        startDate: moment().subtract(1, 'month').format("MM/DD/YYYY"),
        endDate: moment(new Date()).format("MM/DD/YYYY"),
    }
    const lastYear = {
        startDate: moment().subtract(1, 'year').format("MM/DD/YYYY"),
        endDate: moment(new Date()).format("MM/DD/YYYY"),
    }

    if (date.startDate === today.startDate && date.endDate === today.endDate) {
        dateItem.predefinedTag = 'today'
        dateItem.dateString = 'Today'
    }
    else if (date.startDate === thisWeek.startDate && date.endDate === thisWeek.endDate) {
        dateItem.predefinedTag = 'thisWeek'
        dateItem.dateString = 'This Week'
    }
    else if (date.startDate === thisMonth.startDate && date.endDate === thisMonth.endDate) {
        dateItem.predefinedTag = 'thisMonth'
        dateItem.dateString = 'This Month'
    }
    else if (date.startDate === thisYear.startDate && date.endDate === thisYear.endDate) {
        dateItem.predefinedTag = 'thisYear'
        dateItem.dateString = 'This Year'
    }
    else if (date.startDate === lastDay.startDate && date.endDate === lastDay.endDate) {
        dateItem.predefinedTag = 'lastDay'
        dateItem.dateString = 'Last Day'
    }
    else if (date.startDate === lastWeek.startDate && date.endDate === lastWeek.endDate) {
        dateItem.predefinedTag = 'lastWeek'
        dateItem.dateString = 'Last Week'
    }
    else if (date.startDate === lastMonth.startDate && date.endDate === lastMonth.endDate) {
        dateItem.predefinedTag = 'lastMonth'
        dateItem.dateString = 'Last Month'
    }
    else if (date.startDate === lastYear.startDate && date.endDate === lastYear.endDate) {
        dateItem.predefinedTag = 'lastYear'
        dateItem.dateString = 'Last Year'
    }
    else if (date.startDate === allTime.startDate && date.endDate === allTime.endDate) {
        dateItem.predefinedTag = 'allTime'
        dateItem.dateString = 'All Time'
    }
    else {
        dateItem.predefinedTag = null
        dateItem.dateString = moment(date.startDate, "MM/DD/YYYY").format("DD/MM/YYYY") + ' - ' + moment(date.endDate, "MM/DD/YYYY").format("DD/MM/YYYY")
    }

    return dateItem
}

export function nameTag(string) {
    let nameTag = "";
    if (string) {
        let arrayOfString = string.split(" ");
        if (arrayOfString[0]) {
            nameTag += arrayOfString[0][0];
        }
        if (arrayOfString[1]) {
            nameTag += arrayOfString[1][0];
        }
    }

    return nameTag.toUpperCase();
}

export function eraseCookie(name) {
    createCookie(name, "", -1);
}

export function createCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    let encodedValue = encodeParam(value);
    const baseURL = window.location.href;
    const domain = baseURL.includes('localhost') ? 'localhost' : '.popleads.com';
    document.cookie = name + "=" + encodedValue + expires + "; SameSite=None; Secure; path=/; Domain=" + domain;
}

export function getCookieValue(name) {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
}

function encodeParam(s) { return encodeURIComponent(s); }

export function getDynamicValue(columnsList, fieldName) {
    //console.log("cl: ", columnsList.length)
    if (!columnsList) return
    for (let i = 0; i < columnsList.length; i++) {
        if (columnsList[i].fieldName === fieldName) {
            return columnsList[i].fieldValue
        }
    }
}

export function getUrlQueryParam(paramName) {
    const url = new URL(decodeURIComponent(window.location.href));
    return url.searchParams.get(paramName);
}

export function getCompactNumber(number) {
    if (number < 1000) return number;
    else if (number >= 1000 && number < 1000000) {
        let shortNumber = number / 1;
        shortNumber = parseInt(shortNumber);
        shortNumber /= 1000;
        shortNumber += "K";
        return shortNumber;
    }
    else if (number >= 1000000 && number < 1000000000) {
        let shortNumber = number / 1000;
        shortNumber = parseInt(shortNumber);
        shortNumber /= 1000;
        shortNumber += "M";
        return shortNumber;
    }
    else {
        let shortNumber = number / 1000000;
        shortNumber = parseInt(shortNumber);
        shortNumber /= 1000;
        shortNumber += "B";
        return shortNumber;
    }

}

export async function waitAndMaybeReject() {
    // Wait one second
    await new Promise(r => setTimeout(r, 1000));
    // Toss a coin
    const isHeads = Boolean(Math.round(Math.random()));

    if (isHeads) return 'yay';
    throw Error('Boo!');
}

export function getTrainer(trainer) {
    if (trainer) {
        trainer.expertise = "";
        trainer.bio = "";
        trainer.inHouse = 0;
        trainer.lvt = 0;
        trainer.nationality = [];
        trainer.country = [];
        trainer.visa = [];
        trainer.passport = [];
        trainer.portfolio = [];

        for (let j = 0; j < trainer.trainerInfo.length; j++) {
            const tIObj = trainer.trainerInfo[j]
            if (tIObj.type === "overview" && tIObj.name === "expertise") trainer.expertise = tIObj
            else if (tIObj.type === "overview" && tIObj.name === "bio") trainer.bio = tIObj
            else if (tIObj.type === "dailyRate" && tIObj.name === "In-house") trainer.inHouse = tIObj
            else if (tIObj.type === "dailyRate" && tIObj.name === "LVT") trainer.lvt = tIObj
            else if (tIObj.type === "nationality") trainer.nationality.push(tIObj)
            else if (tIObj.type === "country") trainer.country.push(tIObj)
            else if (tIObj.type === "visa") trainer.visa.push(tIObj)
            else if (tIObj.type === "passport") trainer.passport.push(tIObj)
            else if (tIObj.type === "portfolio") trainer.portfolio.push(tIObj)
        }
    }
    return (trainer)
}

export function getCountriesForSelect(dynamicSchemaFields) {
    let countries = [];
    for (let i = 0; i < dynamicSchemaFields.length; i++) {
        if (dynamicSchemaFields[i].fieldName === "Country") {
            if (typeof dynamicSchemaFields[i].fieldType === "string")
                dynamicSchemaFields[i].fieldType = JSON.parse(dynamicSchemaFields[i].fieldType)
            for (var key in dynamicSchemaFields[i].fieldType.data) {
                countries.push({ label: key, value: key });
            }

            return countries;
        }
    }
}

export function getDynamicSchemaForSelect(dynamicSchemaFields, fieldName, defaultValue) {
    let data = [];
    if (dynamicSchemaFields)
        for (let i = 0; i < dynamicSchemaFields.length; i++) {

            if (dynamicSchemaFields[i].fieldName === fieldName) {
                if (defaultValue) defaultValue.push({ label: dynamicSchemaFields[i].defaultValue, value: dynamicSchemaFields[i].defaultValue });
                if (typeof dynamicSchemaFields[i].fieldType === "string")
                    dynamicSchemaFields[i].fieldType = JSON.parse(dynamicSchemaFields[i].fieldType);

                for (let j = 0; j < dynamicSchemaFields[i].fieldType.data.length; j++) {
                    data.push({ label: dynamicSchemaFields[i].fieldType.data[j], value: dynamicSchemaFields[i].fieldType.data[j] });
                }

                return data;
            }
        }
}


export function getCitiesForSelect(dynamicSchemaFields, country) {
    let cities = [];
    if (dynamicSchemaFields)
        for (let i = 0; i < dynamicSchemaFields.length; i++) {
            if (dynamicSchemaFields[i].fieldName === "Country") {
                //console.log("dynamicSchemaFields[i]: ",dynamicSchemaFields[i])
                if (typeof dynamicSchemaFields[i].fieldType === "string")
                    dynamicSchemaFields[i].fieldType = JSON.parse(dynamicSchemaFields[i].fieldType)

                for (var key in dynamicSchemaFields[i].fieldType.data) {

                    if (typeof dynamicSchemaFields[i].fieldType.data[key] === "string")
                        dynamicSchemaFields[i].fieldType.data[key] = JSON.parse(dynamicSchemaFields[i].fieldType.data[key])


                    if ((country && key === country.value) || (!country)) {
                        dynamicSchemaFields[i].fieldType.data[key].sort()
                        for (let j = 0; j < dynamicSchemaFields[i].fieldType.data[key].length; j++)
                            cities.push({ label: dynamicSchemaFields[i].fieldType.data[key][j], value: dynamicSchemaFields[i].fieldType.data[key][j] });
                    }

                }
                return cities;
            }
        }
}

export function getVisasForSelect(dynamicSchemaFields) {
    let visas = [];
    for (let i = 0; i < dynamicSchemaFields.length; i++) {
        if (dynamicSchemaFields[i].fieldName === "Visa") {
            if (typeof dynamicSchemaFields[i].fieldType === "string")
                dynamicSchemaFields[i].fieldType = JSON.parse(dynamicSchemaFields[i].fieldType)
            console.log("dynamicSchemaFields[i].fieldType: ", dynamicSchemaFields[i].fieldType)
            for (let j = 0; j < dynamicSchemaFields[i].fieldType.data.length; j++) {
                visas.push({ label: dynamicSchemaFields[i].fieldType.data[j], value: dynamicSchemaFields[i].fieldType.data[j] });
            }

            return visas;
        }
    }
}

export function getCourseNamesForSelect(dynamicSchemaFields) {
    let courseName = [];
    for (let i = 0; i < dynamicSchemaFields.length; i++) {
        if (dynamicSchemaFields[i].fieldName === "Course Name") {
            if (typeof dynamicSchemaFields[i].fieldType === "string")
                dynamicSchemaFields[i].fieldType = JSON.parse(dynamicSchemaFields[i].fieldType)
            for (let j = 0; j < dynamicSchemaFields[i].fieldType.data.length; j++) {
                courseName.push({ label: dynamicSchemaFields[i].fieldType.data[j], value: dynamicSchemaFields[i].fieldType.data[j] });
            }
            return courseName;
        }
    }
}

export function getPortfolioForSelect(dynamicSchemaFields) {
    let portfolioName = [];
    for (let i = 0; i < dynamicSchemaFields.length; i++) {
        if (dynamicSchemaFields[i].fieldName === "Business Sector") {
            if (typeof dynamicSchemaFields[i].fieldType === "string")
                dynamicSchemaFields[i].fieldType = JSON.parse(dynamicSchemaFields[i].fieldType)
            for (let j = 0; j < dynamicSchemaFields[i].fieldType.data.length; j++) {
                portfolioName.push({ label: dynamicSchemaFields[i].fieldType.data[j], value: dynamicSchemaFields[i].fieldType.data[j] });
            }
            return portfolioName;
        }
    }
}

export function createSelectDataFromArray(array) {
    let dataArr = [];
    for (let i = 0; i < array.length; i++) {
        dataArr.push({ label: array[i], value: array[i] })
    }
    return dataArr;
}

export function trimAndSetNull(input) {
    if (input && input.trim() !== "") return input.trim();
    else return null;
}

export function isAdded(array, name, value, date) {
    return null;
}

export function calcBusinessDays(startDate, endDate) {
    var iWeeks, iDateDiff, iAdjust = 0;
    if (endDate < startDate) return -1; // error code if dates transposed
    if (endDate == startDate) return 1;
    var iWeekday1 = new Date(startDate).getDay(); // day of week
    var iWeekday2 = new Date(endDate).getDay();
    if (iWeekday1 != 0) {//week start from Monday
        iWeekday1 = (iWeekday1 == 0) ? 7 : iWeekday1; // change Sunday from 0 to 7
        iWeekday2 = (iWeekday2 == 0) ? 7 : iWeekday2;
        if ((iWeekday1 > 5) && (iWeekday2 > 5)) iAdjust = 1; // adjustment if both days on weekend
        iWeekday1 = (iWeekday1 > 5) ? 5 : iWeekday1; // only count weekdays
        iWeekday2 = (iWeekday2 > 5) ? 5 : iWeekday2;
    } else if (iWeekday1 == 0) {//week start from Sunday
        if ((iWeekday1 > 4) && (iWeekday2 > 4)) iAdjust = 1; // adjustment if both days on weekend
        if ((iWeekday1 === 0) && (iWeekday2 > 4)) iAdjust = 1;
        iWeekday1 = (iWeekday1 > 4) ? 5 : iWeekday1; // only count weekdays
        iWeekday2 = (iWeekday2 > 4) ? 5 : iWeekday2;
    }
    // calculate differnece in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
    iWeeks = Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) / 604800000)

    if (iWeekday1 <= iWeekday2) {
        iDateDiff = (iWeeks * 5) + (iWeekday2 - iWeekday1)
    } else {
        iDateDiff = ((iWeeks + 1) * 5) - (iWeekday1 - iWeekday2)
    }

    iDateDiff -= iAdjust // take into account both days on weekend
    return (iDateDiff + 1); // add 1 because dates are inclusive
}

export function getSolrInputListValue(listValueArray) {
    let result = "";
    listValueArray.map((item) => {
        if (result === "") result += item.value
        else result += "_¡_" + item.value
    })

    return result;
}

export function getArrayInputListValue(listValueArray) {
    let result = [];
    listValueArray.map((item) => result.push(item.value))
    return result;
}

export function generateCourseWebLink(campaignType, ds, startDate, endDate, city, country) {

    let courseName = getDynamicValue(ds, "Course Name");

    if (campaignType === "LVT Public" || campaignType === "Public") {

        const monthNames = constants.MONTH_NAMES;
        let domain = "http://www.leoron.com/";

        if (campaignType.indexOf('LVT') === -1) {
            if (city !== "" && city != null && city !== "reset") {
                courseName += "-" + city;
            }

            if (country !== "" && country != null && country !== "reset") {
                courseName += "-" + country;
            }
        } else {
            courseName += "-" + "live-virtual-training"
        }
        const slugify = require('slugify');
        courseName = domain + slugify(courseName, { lower: true, trim: true });
        if (startDate != null && endDate != null) {
            let sDate = new Date(startDate);
            let eDate = new Date(endDate);
            if (sDate.getMonth() === eDate.getMonth()) {
                courseName += "-" + monthNames[sDate.getMonth()] + "-" + (sDate.getDate() < 10 ? "0" + sDate.getDate() : sDate.getDate()) + "-" + (eDate.getDate() < 10 ? "0" + eDate.getDate() : eDate.getDate());
            } else {
                courseName += "-" + monthNames[sDate.getMonth()] + "-" + (sDate.getDate() < 10 ? "0" + sDate.getDate() : sDate.getDate()) + "-" + monthNames[eDate.getMonth()] + "-" + (eDate.getDate() < 10 ? "0" + eDate.getDate() : eDate.getDate());
            }
        }

        return courseName;
    } else {
        return null;
    }
}
export function getLoadOptionsObjForASYNCSelect(arr) {
    return { options: arr, hasMore: false }
}

export function objectArrayDeepCopy(array) {
    return JSON.parse(JSON.stringify(array));
}

export function findAndReplaceURL(obj) {
    obj = obj.replaceAll("xxxampxxx", "&");
    obj = obj.replaceAll("xxxqmaxxx", "?");
    obj = obj.replaceAll("xxxequxxx", "=");
    obj = obj.replaceAll("xxxcomxxx", ",");
    obj = obj.replaceAll("xxxdotxxx", ".");
    obj = obj.replaceAll("xxxdquxxx", '"');
    obj = obj.replaceAll("xxxsquxxx", "'");
    return obj;
}

export function asideFilterFromURL() {
    let asideCountry = JSON.parse(getUrlQueryParam("asideCountry"));
    let asideIndustry = JSON.parse(getUrlQueryParam("asideIndustry"));
    let asideAccount = JSON.parse(getUrlQueryParam("asideAccount"));
    let asidePortfolio = JSON.parse(getUrlQueryParam("asidePortfolio"));

    if (asideCountry) {
        console.log("asideCountry: ", asideCountry)
        for (var i = 0; i < asideCountry.length; i++)
            asideCountry[i] = findAndReplaceURL(asideCountry[i]);
    }

    if (asideIndustry) {
        console.log("asideIndustry: ", asideIndustry)

        for (var i = 0; i < asideIndustry.length; i++)
            asideIndustry[i] = findAndReplaceURL(asideIndustry[i]);
    }

    if (asideAccount) {
        console.log("asideAccount: ", asideAccount)

        for (var i = 0; i < asideAccount.length; i++)
            asideAccount[i] = findAndReplaceURL(asideAccount[i]);
    }

    if (asidePortfolio) {
        console.log("asidePortfolio: ", asidePortfolio)

        for (var i = 0; i < asidePortfolio.length; i++)
            asidePortfolio[i] = findAndReplaceURL(asidePortfolio[i]);
    }

    return { asideCountry, asideIndustry, asideAccount, asidePortfolio }
}



export const checkIfParsable = (val, language, debuggerKey) => {
    // if (debuggerKey) debugger
    let result = ""
    // console.log("checkIfParsable val", val)

    try {
        if (typeof val === "string") {
            result = JSON.parse(val)
        } else {
            result = val
        }
        result = (result && result.hasOwnProperty([language.value])) ? result[language.value] : ""
        if (debuggerKey === "testimonial") console.log("checkIfParsable result", result)
        // if(typeof result === "string") checkIfParsable(result)
    } catch (error) {
        if (val && val[language.value] !== undefined) {
            result = val[language.val]
        } else {
            result = val
        }
        if (debuggerKey) {
            console.log("checkIfParsable error", error)
        }
        // console.log("checkIfParsable result", result)
    }

    return result
}

export const checkIfParsableWithoutLanguage = (val) => {
    let result = ""
    try {
        result = JSON.parse(val)
        if (typeof result === "string") checkIfParsableWithoutLanguage(result)
    } catch (error) {
        result = val
    }
    return result
}

export const getEditedOrCurrentValue = (editedValue, initialValue, checkType) => {
    let value = null;
    if(editedValue !== null && editedValue !== undefined){
        if(checkType){
            if(typeof editedValue !== "string"){
                value = JSON.stringify(editedValue)
            } else {
                value = editedValue;
            }
        } else {
            value = editedValue;
        }
    } else if (initialValue) {
        if(checkType){
            if(typeof initialValue !== "string"){
                value = JSON.stringify(initialValue);
            } else {
                value = initialValue;
            }
        } else {
            value = initialValue;
        }
    }
    return value;
}
