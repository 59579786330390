export const actionTypes = {
  SaveCompanyUsers: "[App] Save Company Users Action",
  SaveCompanyDepartments: "[App] Save Company Departments Action",
  SaveCampaignTypes: "[App] Save Campaign Types Action",
  SavePdfData: "[App] Save Pdf Data",
  ClearPdfData: "[App] Clear Pdf Data",
  PdfLoading: "[App] Pdf Loading",
  SetEmployeeFilter: "[App] Set Employee Filter",
  SetDepartmentFilter: "[App] Set Department Filter",
  SetDateFilter: "[App] Set Date Filter",
  SetTrainerFilter: "[App] Set Trainer Filter",
  SetCampaignTypeListFilter: "[App] Set Campaign Type List Filter",
  SetDepartmentFilter: "[App] Set Department Filter",
  SetDateFilter: "[App] Set Date Filter",
  SetDateFilterSurvey: "[App] Set Date Filter Survey",
  SetTypeFilter: "[App] Set Type Filter",
  SetIndustryFilter: "[App] Set Industry Filter",
  SetSearchInput: "[App] Set Search Input",
  SetCurrency: "[App] Set Currency",
  SetDynamicSchemaDataManagement: "[App] Set Dynamic Schema Data Management",
  SetCampaignTemplate: "[App] Set Campaign Template",
  SetInquiryTypeFilter: "[App] Set Inquiry Type Filter",
  SetFranchiseFilter: "[App] Set Franchise Filter",
};

const initialReportsState = {
  companyUsers: undefined,
  companyDepartments: undefined,
  campaignTypes: undefined,
  pdfDivId: undefined,
  pdfScaleSize: undefined,
  pdfDownloadName: undefined,
  isPdfLoading: false,
  employeeFilter: false,
  departmentFilter: false,
  dateFilter: false,
  trainerFilter: false,
  campaignTypeListFilter: false,
  departmentFilter: false,
  dateFilter: false,
  dateFilterSurvey: false,
  typeFilter: false,
  industryFilter: false,
  inquiryTypeFilter: false,
  franchiseFilter: false,
  searchInput: false,
  currency: { "currency": "USD", "sign": "$" },
  dynamicSchemaDataManagement: undefined,
  campaignTemplate: undefined
};

export const reducer = (state = initialReportsState, action) => {
  switch (action.type) {

    case actionTypes.SaveCompanyUsers: {
      //console.log("actionType.SelectEmployee")
      const { companyUsers } = action.payload;
      return { ...state, companyUsers }
    }

    case actionTypes.SaveCompanyDepartments: {
      //console.log("actionType.SelectEmployee")
      const { companyDepartments } = action.payload;
      return { ...state, companyDepartments }
    }

    case actionTypes.SaveCampaignTypes: {
      //console.log("actionType.SelectDepartment")
      const { campaignTypes } = action.payload;
      return { ...state, campaignTypes }
    }

    case actionTypes.SavePdfData: {
      //console.log("actionType.SelectDepartment")
      const { pdfData } = action.payload;
      console.log("pdfData = ", pdfData);
      return { ...state, pdfDivId: pdfData.pdfDivId, pdfScaleSize: pdfData.pdfScaleSize, pdfDownloadName: pdfData.pdfDownloadName }
    }

    case actionTypes.ClearPdfData: {
      //console.log("actionType.SelectDepartment")
      return { ...state, pdfDivId: undefined, pdfScaleSize: undefined, pdfDownloadName: undefined }
    }

    case actionTypes.PdfLoading: {
      //console.log("actionType.SelectDepartment")
      const { isPdfLoading } = action.payload;
      return { ...state, isPdfLoading }
    }

    case actionTypes.SetEmployeeFilter: {
      const { employeeFilter } = action.payload;
      return { ...state, employeeFilter }
    }

    case actionTypes.SetTrainerFilter: {
      const { trainerFilter } = action.payload;
      return { ...state, trainerFilter }
    }

    case actionTypes.SetCampaignTypeListFilter: {
      const { campaignTypeListFilter } = action.payload;
      return { ...state, campaignTypeListFilter }
    }

    case actionTypes.SetDepartmentFilter: {
      const { departmentFilter } = action.payload;
      return { ...state, departmentFilter }
    }

    case actionTypes.SetDateFilter: {
      const { dateFilter } = action.payload;
      return { ...state, dateFilter }
    }
    case actionTypes.SetDateFilterSurvey: {
      const { dateFilterSurvey } = action.payload;
      return { ...state, dateFilterSurvey }
    }

    case actionTypes.SetTypeFilter: {
      const { typeFilter } = action.payload;
      return { ...state, typeFilter }
    }

    case actionTypes.SetIndustryFilter: {
      const { industryFilter } = action.payload;
      return { ...state, industryFilter }
    }

    case actionTypes.SetSearchInput: {
      const { searchInput } = action.payload;
      return { ...state, searchInput }
    }

    case actionTypes.SetCurrency: {
      const { currency } = action.payload;
      return { ...state, currency }
    }

    case actionTypes.SetDynamicSchemaDataManagement: {
      const { dynamicSchemaDataManagement } = action.payload;
      return { ...state, dynamicSchemaDataManagement }
    }

    case actionTypes.SetCampaignTemplate: {
      const { campaignTemplate } = action.payload;
      return { ...state, campaignTemplate }
    }

    case actionTypes.SetInquiryTypeFilter: {
      const { inquiryTypeFilter } = action.payload;
      return { ...state, inquiryTypeFilter }
    }

    case actionTypes.SetFranchiseFilter: {
      const { franchiseFilter } = action.payload;
      return { ...state, franchiseFilter }
    }

    default:
      return state;
  }
}

export const actions = {
  saveCompanyUsers: (companyUsers) => ({
    type: actionTypes.SaveCompanyUsers,
    payload: { companyUsers }
  }),
  saveCompanyDepartments: (companyDepartments) => ({
    type: actionTypes.SaveCompanyDepartments,
    payload: { companyDepartments }
  }),
  saveCampaignTypes: (campaignTypes) => ({
    type: actionTypes.SaveCampaignTypes,
    payload: { campaignTypes }
  }),
  savePdfData: (pdfData) => ({
    type: actionTypes.SavePdfData,
    payload: { pdfData }
  }),
  clearPdfData: () => ({
    type: actionTypes.ClearPdfData,
    payload: {}
  }),
  pdfLoading: (isPdfLoading) => ({
    type: actionTypes.PdfLoading,
    payload: { isPdfLoading }
  }),
  setEmployeeFilter: (employeeFilter) => ({
    type: actionTypes.SetEmployeeFilter,
    payload: { employeeFilter }
  }),
  setTrainerFilter: (trainerFilter) => ({
    type: actionTypes.SetTrainerFilter,
    payload: { trainerFilter }
  }),
  setCampaignTypeListFilter: (campaignTypeListFilter) => ({
    type: actionTypes.SetCampaignTypeListFilter,
    payload: { campaignTypeListFilter }
  }),
  setDepartmentFilter: (departmentFilter) => ({
    type: actionTypes.SetDepartmentFilter,
    payload: { departmentFilter }
  }),
  setDateFilter: (dateFilter) => ({
    type: actionTypes.SetDateFilter,
    payload: { dateFilter }
  }),

  setDateFilterSurvey: (dateFilterSurvey) => ({
    type: actionTypes.SetDateFilterSurvey,
    payload: { dateFilterSurvey }
  }),
  setInquiryTypeFilter: (inquiryTypeFilter) => ({
    type: actionTypes.SetInquiryTypeFilter,
    payload: { inquiryTypeFilter }
  }),

  setFranchiseFilter: (franchiseFilter) => ({
    type: actionTypes.SetFranchiseFilter,
    payload: { franchiseFilter }
  }),

  setTypeFilter: (typeFilter) => ({
    type: actionTypes.SetTypeFilter,
    payload: { typeFilter }
  }),

  setIndustryFilter: (industryFilter) => ({
    type: actionTypes.SetIndustryFilter,
    payload: { industryFilter }
  }),

  setSearchInput: (searchInput) => ({
    type: actionTypes.SetSearchInput,
    payload: { searchInput }
  }),

  setCurrency: (currency) => ({
    type: actionTypes.SetCurrency,
    payload: { currency }
  }),

  setDynamicSchemaDataManagement: (dynamicSchemaDataManagement) => ({
    type: actionTypes.SetDynamicSchemaDataManagement,
    payload: { dynamicSchemaDataManagement }
  }),

  setCampaignTemplate: (campaignTemplate) => ({
    type: actionTypes.SetCampaignTemplate,
    payload: { campaignTemplate }
  }),
};