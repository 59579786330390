/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import QuickActions from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";

//PopLeads Change
import Filter from "../../../../app/popleads/modules/filters/Filter";

import Pdf from "../../../../app/popleads/app/partials/Pdf";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import SurveyBar from "./components/SurveyBar";
import QuickActionsCourseDetail from "./components/QuickActionsCourseDetail";
import { MOBILE_BREAKPOINT } from "../../../../app/popleads/app/contants";

export function SubHeader() {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const uiService = useHtmlClassService();
    const location = useLocation();
    const subheader = useSubheader();

    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            subheaderMobileToggle: objectPath.get(uiService.config, "subheader.mobile-toggle"),
            subheaderCssClasses: uiService.getClasses("subheader", true),
            subheaderContainerCssClasses: uiService.getClasses("subheader_container", true)
        };
    }, [uiService]);

    useLayoutEffect(() => {
        const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
        const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
        const breadcrumbs = aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs;
        subheader.setBreadcrumbs(breadcrumbs);
        subheader.setTitle(aside && aside.title && aside.title.length > 0 ? aside.title : header.title);
        // eslint-disable-next-line
    }, [location.pathname]);

    // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
    useEffect(() => { }, [subheader]);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    return (
        <>
            <div id="kt_subheader" className={`subheader py-4 pl- py-lg-4  ${layoutProps.subheaderCssClasses}`}>
                <div className={`${layoutProps.subheaderContainerCssClasses}`}>
                    {/* Info */}
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            {
                                //PopLeads Change
                            }

                            <div className="subHeaderPL">
                                <div className="d-flex align-items-center mr-5">
                                    <h5 className="text-dark my-0 mr-0 mr-sm-5  only-text-page-heading color-secondary">
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    <strong>{subheader.title}</strong>
                                                </Tooltip>
                                            }>
                                            <span>{subheader.title}</span>
                                        </OverlayTrigger>

                                        {/*<small></small>*/}
                                    </h5>
                                </div>

                                <BreadCrumbs items={subheader.breadcrumbs} />
                            </div>
                        </div>
                        {windowSize.innerWidth > MOBILE_BREAKPOINT && <div className="d-flex align-items-center ">
                            <Filter />

                            <Pdf></Pdf>

                            <QuickActions />

                            {/* PopLeads Change pdf icon*/ subheader.pdfCallback && (
                                <div className="d-flex align-items-center">
                                    <h5 className="font-weight-bold mr-5">
                                        <>
                                            <button onClick={subheader.pdfCallback}>PDF</button>
                                        </>
                                        {/* PopLeads Change <small></small>*/}
                                    </h5>
                                </div>
                            )}

                            <SurveyBar />
                        </div>}

                        {/* Toolbar */}
                        {/* <div className="d-flex align-items-center">
            <a href="#" className="btn btn-light btn-sm font-weight-bold" id="kt_dashboard_daterangepicker"
               data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
              <span className="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today</span>
              <span className="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">Aug 16</span>
            </a>
            <QuickActions/>
          </div> */}

                    </div>
                </div>

            </div>

            {windowSize.innerWidth <= 426 &&
                <div className={`my-6 ${layoutProps.subheaderContainerCssClasses}`}>
                    <div className="d-flex align-items-center justify-content-end py-0">
                        <Filter />

                        <Pdf></Pdf>

                        <QuickActions />
                        <QuickActionsCourseDetail />

                        {/* PopLeads Change pdf icon*/ subheader.pdfCallback && (
                            <div className="d-flex align-items-center">
                                <h5 className="font-weight-bold mr-5">
                                    <>
                                        <button onClick={subheader.pdfCallback}>PDF</button>
                                    </>
                                    {/* PopLeads Change <small></small>*/}
                                </h5>
                            </div>
                        )}

                        <SurveyBar />
                    </div>
                </div>
            }
        </>



    );
}
