import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import SearchInput from './../../../../app/partials/SearchInput';

export default class TextInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            inputName: '',
            error: undefined,
            action: '',
            src: ''
        }
    }

    componentDidMount() {
        if (this.props.object && this.props.object.actions) {
            this.setState({ action: this.props.object.actions[0] });
            this.props.onActionChange(this.props.object.actions[0])
        }

        if (this.props.inputValue)
            this.setState({ inputValue: this.props.inputValue })

        if (this.props.inputName)
            this.setState({ inputName: this.props.inputName })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.inputValue !== this.props.inputValue) {
            this.setState({ inputValue: this.props.inputValue })
        }

        if (prevProps.error !== this.props.error) {
            this.setState({ error: this.props.error })
        }
    }

    render() {
        return <div className={`${!this.props.noRow ? 'row' : ''} ${this.props.containerClassName ? this.props.containerClassName : ''}`}>
            {this.props.object && this.props.object.actions &&
                <div className='col-sm-2'>
                    <select className='actionSelect' onChange={(action) => {
                        this.setState({ action: action.target.value });
                        this.props.onActionChange(action.target.value)
                    }}>
                        {
                            this.props.object.actions.map((object) => <option>{object}</option>)
                        }
                    </select>
                </div>
            }

            <div className={`${this.props.className && this.props.className} ${!this.props.noRow ? this.props.object && this.props.object.actions ? this.state.action === "[]" ? "col-sm-5" : "col-sm-10" : 'col-sm-12' : ''}`}>
                <DebounceInput
                    minLength={1}
                    debounceTimeout={300}
                    onChange={(e) => {
                        this.setState({ inputValue: e.target.value })
                        this.props.onInputChange(e.target.value)
                    }}
                    className={`${this.props.className && this.props.className} form-control`}
                    placeholder={this.state.action === "[]" ? this.props.object.name + " From" : this.props.object.name}
                    id="kt_datatable_search_query"
                    value={this.state.inputValue}
                />
                {this.state.error && this.state.error.inputName === this.state.inputName && <span className='textInputErrorMessage'>{this.state.error.errorMessage}</span>}
            </div>

            {this.state.action === "[]" &&
                <div className=''>
                    <DebounceInput
                        minLength={3}
                        debounceTimeout={300}
                        onChange={(e) => {
                            this.setState({ inputValue: e.target.value })
                            this.props.onInputChange(e.target.value)
                        }}
                        className={`${this.props.className && this.props.className} form-control`}
                        placeholder={this.state.action === "[]" ? this.props.object.name + " From" : this.props.object.name}
                        id="kt_datatable_search_query"
                        value={this.state.inputValue}
                    />
                </div>
            }

        </div>
    }
}
